import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import * as Sentry from "@sentry/angular";

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  environment: environment.sentry.environment,
  dsn: environment.sentry.dns,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: environment.sentry.tracesSampleRate,
  tracePropagationTargets: environment.sentry.tracePropagationTargets,
  replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);