import { Component, Input, OnInit } from '@angular/core';
import { AllowanceActivityInterface, GoalActivityInterface, InvestmentActivityInterface, LoanActivityInterface, TaskActivityInterface, TransactionActivityInterface } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'kc-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent  implements OnInit {
  @Input() activities: (AllowanceActivityInterface | GoalActivityInterface | InvestmentActivityInterface | LoanActivityInterface | TaskActivityInterface | TransactionActivityInterface)[] = [];

  constructor() { }

  ngOnInit() {}

}
