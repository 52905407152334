<div class="family-card" expand="block" *ngIf="family">
  <ion-text class="card-title ion-text-left">{{ family.name }}</ion-text>
  <div class="content">
    <ion-text class="card-balance-title ion-text-left">Funds in Family</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ family.country.currency.symbol }} {{ family.balance | number:'1.0-2' }}</ion-text>

    <br>

    <ion-text class="card-balance-title ion-text-left">Available Funds</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ family.account.country.currency.symbol }} {{ family.account.balance | number:'1.0-2' }}</ion-text>

    <br>

    <ion-text class="card-balance-title ion-text-left">Goal Savings</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ family.account.country.currency.symbol }} {{ family.account.goalreached ? ( family.account.goalreached | number:'1.0-2' ) : '0' }} / {{ family.account.goaltarget ? ( family.account.goaltarget | number:'1.0-2' ) : '0' }}</ion-text>

    <br>

    <ion-text class="card-balance-title ion-text-left">Investments</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ family.account.country.currency.symbol }} {{ family.account.investment ? ( family.account.investment | number:'1.0-2' ) : '0' }}</ion-text>

    <div class="card-members">
      <img class="family-member" *ngFor="let kiddy_account of family.kiddy_accounts" [src]="( kiddy_account.kiddy?.avatarversion ) ? ( staticBaseUrl + '/profiles/' + kiddy_account.kiddy?.id + '/avatars/' + kiddy_account.kiddy?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
    </div>
  </div>
</div>

<div class="family-card" expand="block" *ngIf="business">
  <ion-text class="card-title ion-text-left">{{ business.name }}</ion-text>
  <div class="content">
    <ion-text class="card-balance-title ion-text-left">Funds in Business</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ business.country.currency.symbol }} {{ business.balance | number:'1.0-2' }}</ion-text>

    <br>

    <div class="card-members">
      <img class="family-member" *ngFor="let manager of business.managers" [src]="( manager.profile.avatarversion ) ? ( staticBaseUrl + '/profiles/' + manager.profile.id + '/avatars/' + manager.profile.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
    </div>
  </div>
</div>

<div class="family-card" expand="block" *ngIf="school">
  <ion-text class="card-title ion-text-left">{{ school.name }}</ion-text>
  <div class="content">
    <ion-text class="card-balance-title ion-text-left">Funds in Schoo</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ school.country.currency.symbol }} {{ school.balance | number:'1.0-2' }}</ion-text>

    <br>

    <div class="card-members">
      <img class="family-member" *ngFor="let staff of school.staff" [src]="( staff.staff.avatarversion ) ? ( staticBaseUrl + '/profiles/' + staff.staff.id + '/avatars/' + staff.staff.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
    </div>
  </div>
</div>

<div class="family-card" expand="block" *ngIf="account">
  <ion-text class="card-title ion-text-left">{{ account.name }}</ion-text>
  <div class="content">
    <ion-text class="card-balance-title ion-text-left">Balance</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ account.country.currency.symbol }} {{ account.balance | number:'1.0-2' }}</ion-text>

    <br>

    <ng-container *ngIf="account.type === AccountTypeEnum.Kiddy">
      <ion-text class="card-balance-title ion-text-left">Goal Savings</ion-text>
      <ion-text class="card-balance-amount ion-text-left">{{ account.country.currency.symbol }} {{ account.goalreached ? ( account.goalreached | number:'1.0-2' ) : '0' }} / {{ account.goaltarget ? ( account.goaltarget | number:'1.0-2' ) : '0' }}</ion-text>
  
      <br>
  
      <ion-text class="card-balance-title ion-text-left">Investments</ion-text>
      <ion-text class="card-balance-amount ion-text-left">{{ account.country.currency.symbol }} {{ account.investment ? ( account.investment | number:'1.0-2' ) : '0' }}</ion-text>
  
      <div class="card-members">
        <img class="family-member" [src]="( account.kiddy?.avatarversion ) ? ( staticBaseUrl + '/profiles/' + account.kiddy?.id + '/avatars/' + account.kiddy?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
      </div>  
    </ng-container>

    <ng-container *ngIf="account.type === AccountTypeEnum.SchoolKiddy">
      <ion-text class="card-balance-title ion-text-left">School</ion-text>
      <ion-text class="card-balance-amount ion-text-left">{{ account.school?.name }}</ion-text>
  
      <br>
  
      <ion-text class="card-balance-title ion-text-left">Location</ion-text>
      <ion-text class="card-balance-amount ion-text-left">{{ account.school?.location }}</ion-text>
  
      <div class="card-members">
        <img class="family-member" [src]="( account.kiddy?.avatarversion ) ? ( staticBaseUrl + '/profiles/' + account.kiddy?.id + '/avatars/' + account.kiddy?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
      </div>  
    </ng-container>

    <ng-container *ngIf="account.type === AccountTypeEnum.Business && business">
      <ion-text class="card-balance-title ion-text-left">Business</ion-text>
      <ion-text class="card-balance-amount ion-text-left">{{ business.name }}</ion-text>
  
      <br>

      <div class="card-members">
        <img class="family-member" *ngFor="let manager of business.managers" [src]="( manager.profile.avatarversion ) ? ( staticBaseUrl + '/profiles/' + manager.profile.id + '/avatars/' + manager.profile.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
      </div>  
    </ng-container>
  </div>
</div>
