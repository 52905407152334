import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyFrequencyEnum, TaskFrequencyEnum, TaskInterface, TaskStatusEnum } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'kc-task-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent  implements OnInit {
  @Input() task!: TaskInterface;
  @Input() isGuardian: boolean = false;
  @Input() isKiddy: boolean = false;
  @Input() taskEditForm!: FormGroup

  @Output() taskStatusChange = new EventEmitter<any>();
  @Output() editFamilyTaskFormSubmit = new EventEmitter<any>();

  isEditting: boolean = false;
  TaskFrequencyEnumValues = Object.values(TaskFrequencyEnum);
  TaskStatusEnumValues = Object.values(TaskStatusEnum);
  NotifyFrequencyEnumValues = Object.values(NotifyFrequencyEnum);
  KiddyTaskStatusEnumValues = this.TaskStatusEnumValues.filter(status => (
      status === TaskStatusEnum.Accepted || 
      status === TaskStatusEnum.Done || 
      status === TaskStatusEnum.Declined
    )
  );
  CustodianTaskStatusEnumValues = this.TaskStatusEnumValues.filter(status => (
      status === TaskStatusEnum.Cancelled || 
      status === TaskStatusEnum.Complete || 
      status === TaskStatusEnum.Pending 
    )
  );

  TaskStatusEnum: TaskStatusEnum[] = [];
  TaskStatuses: TaskStatusEnum[] = [];
  DisabledTaskStatuses: TaskStatusEnum[] = [];

  familyTaskEditForm: FormGroup = new FormGroup({
    title: new FormControl(''),
    description: new FormControl(''),
    reward: new FormControl(''),
    frequency: new FormControl(''),
    notify: new FormControl(''),
  });

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.TaskStatuses = this.isGuardian ? this.CustodianTaskStatusEnumValues : this.KiddyTaskStatusEnumValues;
    this.DisabledTaskStatuses = this.isGuardian ? this.KiddyTaskStatusEnumValues : this.CustodianTaskStatusEnumValues;
    this.TaskStatusEnum = this.isGuardian ? this.CustodianTaskStatusEnumValues : this.KiddyTaskStatusEnumValues;
  }

  initForm(){
    this.familyTaskEditForm = this.formBuilder.group({
      title: [this.task.title, 
        [
          Validators.required, 
          Validators.minLength(2), 
          Validators.maxLength(30)
        ]
      ],
      description: [this.task.description, 
        [
          Validators.maxLength(400)
        ]
      ],
      reward: [this.task.reward, 
        [
          Validators.required, 
          Validators.min(1), 
        ]
      ],
      frequency: [this.task.frequency, 
        [
          Validators.required, 
          Validators.pattern(this.TaskFrequencyEnumValues.join('|'))
        ]
      ],
      notify: [this.task.notify, 
        [
          Validators.required, 
          Validators.pattern(this.NotifyFrequencyEnumValues.join('|'))
        ]
      ],
    })
  }

  startEditing() {
    this.isEditting = true;
  }

  stopEditing() {
    this.isEditting = false;
  }

  submitForm() {
    if (!this.familyTaskEditForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.familyTaskEditForm.value);
      this.editFamilyTaskFormSubmit.emit(requestData);
    }
  }

  taskStatusSelectChange(event: any){
    if(this.task.status !== event.detail.value) this.taskStatusChange.emit(event.detail.value);
  }

}
