import { Component, Input, OnInit } from '@angular/core';
import { TaskInterface, TaskStatusEnum } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'kc-task-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class TaskListItemComponent  implements OnInit {
  @Input() task!: TaskInterface;
  @Input() route: (string | undefined)[] = [];

  constructor() { }

  ngOnInit() {}

  getStatusColor(status: TaskStatusEnum): string {
    if(status === TaskStatusEnum.Accepted) return 'darkpurple'
    if(status === TaskStatusEnum.Done) return 'amber'
    if(status === TaskStatusEnum.Complete) return 'success'
    if(status === TaskStatusEnum.Pending) return 'medium'
    if(status === TaskStatusEnum.Declined) return 'danger'
    if(status === TaskStatusEnum.Flagged) return 'warning'
    if(status === TaskStatusEnum.Cancelled) return 'danger'
    return 'medium';
  }

}
