import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentCardInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-payment-card-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class PaymentCardCardComponent  implements OnInit {
  @Input() payment_card!: PaymentCardInterface;
  @Input() isGuardian: boolean = false;
  @Input() isKiddy: boolean = false;
  @Input() paymentCardEditForm!: FormGroup
  @Input() route: (string | undefined)[] = [];

  @Output() editFamilyPaymentCardFormSubmit = new EventEmitter<any>();

  staticBaseUrl: string =  environment.staticBaseUrl;

  isEditting: boolean = false;

  familyPaymentCardEditForm: FormGroup = new FormGroup({

  });

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.familyPaymentCardEditForm = this.formBuilder.group({

    })
  }

  startEditing() {
    this.isEditting = true;
  }

  stopEditing() {
    this.isEditting = false;
  }

  submitForm() {
    if (!this.familyPaymentCardEditForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.familyPaymentCardEditForm.value);
      this.editFamilyPaymentCardFormSubmit.emit(requestData);
    }
  }

}
