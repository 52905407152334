import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FirebaseApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAnalytics,getAnalytics,ScreenTrackingService } from '@angular/fire/analytics';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { SharedModule } from 'src/shared/shared.module';
import { AuthService, CountryService, LastActiveService, CapacitorService, StockService } from './services';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MaskitoDirective, MaskitoPipe } from '@maskito/angular';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { AppEnum } from '@kiddy-cash/common';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

let firebaseApp: FirebaseApp;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    HttpClientModule,
    IonicModule.forRoot({
      animated: true, 
      hardwareBackButton: true,
    }), 
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule, 
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MaskitoDirective, 
    MaskitoPipe, 
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideFirebaseApp(() => {
      firebaseApp = initializeApp(environment.firebase)
      return firebaseApp
    }),
    provideAnalytics(() => getAnalytics(firebaseApp)),
    provideMessaging(() => getMessaging(firebaseApp)),
    providePerformance(() => getPerformance(firebaseApp)),
    ScreenTrackingService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [
        AuthService, 
        CountryService, 
        LastActiveService,
        CapacitorService,
        StockService,
        Sentry.TraceService,
      ],
      useFactory: (
        authService: AuthService,
        countryService: CountryService,
        lastActiveService: LastActiveService,
        capacitorService: CapacitorService,
        stockService: StockService,
      ) => async () => {
        await Preferences.configure({
          group: `${environment.firebase.projectId}.${AppEnum.App}`
        })
        await authService.checkStoredUser()
        countryService.fetchCountries()
        countryService.traceLocation()
        lastActiveService.setUp()
        await capacitorService.setUp()
        stockService.setUp()
      },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
