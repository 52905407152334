<ion-card class="business-card">
  <img [src]="( business.logoversion ) ? ( staticBaseUrl + '/businesses/' + business.id + '/logos/' + business.logoversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/business.svg' )" alt="logo" />
  <ion-card-header>
    <ion-card-title>{{ business.name }}</ion-card-title>
    <ion-card-subtitle>
      <span>Balance : {{ business.country.currency.symbol }} {{ business.balance | number:'1.0-2' }}</span>
      <span class="ion-padding-start">Followers : {{ business.total_followers }}</span>
    </ion-card-subtitle>
  </ion-card-header>

  <ion-card-content>
    <p>{{ business.description }}</p>
    <ion-list>
      <ion-list-header *ngIf="business.managers?.length">
        <ion-label>Managers</ion-label>
      </ion-list-header>

      <ion-item *ngFor="let manager of business.managers">
        <ion-avatar aria-hidden="true" slot="start">
          <img [src]="( manager.profile.avatarversion ) ? ( staticBaseUrl + '/profiles/' + manager.profile.id + '/avatars/' + manager.profile.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
        </ion-avatar>
        <ion-label>
          <h4>{{ manager.profile.firstname }} {{ manager.profile.lastname }}</h4>
          <ion-note color="medium">{{ manager.alias }}</ion-note>
          <ion-note color="medium" *ngIf="manager.roles && manager.roles.length">
            Roles {{ getBusinessManagerRoles(manager.roles) }}
          </ion-note>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>
