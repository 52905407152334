import { Component, Input, OnInit } from '@angular/core';
import { BusinessInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-business-list-item',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class BusinessListComponent  implements OnInit {
  @Input() business!: BusinessInterface;

  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

}
