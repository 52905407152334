<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">Add Manager</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" [strong]="true" [disabled]="!managerForm.valid">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <form [formGroup]="managerForm">

    <ng-container *ngIf="!isCodeVerified">
      <ion-grid>
        <ion-row class="ion-justify-content-between ion-align-items-center">
          <ion-col size="auto">
            <ion-label class="slider-option" [ngClass]="{'active': isPhoneAuth}" (click)="toggleAuthType('phone')">Phone</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-label>or</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-label class="slider-option" [ngClass]="{'active': isEmailAuth}" (click)="toggleAuthType('email')">Email</ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>

    <ion-item class="input-number" *ngIf="isPhoneAuth">
      <ion-list class="ion-no-padding">
        <ion-item lines="none" class="transparent-background" [detail]="false">
          <ion-img *ngIf="selectedCountry" src="{{ staticBaseUrl + '/flags/' + selectedCountry.flag }}" class="country-flag"></ion-img>
        </ion-item>
      </ion-list>

      <ion-item class="phone" lines="none">
        <ion-input 
          [clearInput]="true" 
          placeholder="Managers's Phone number" 
          value="" 
          class="phone-input" 
          color="primary" 
          formControlName="phone" 
          type="tel" 
          inputmode="numeric" 
          [maskito]="maskitoPhoneOptions"
          [maskitoElement]="maskPredicate"
        ></ion-input> 
      </ion-item>
    </ion-item>

    <ion-item *ngIf="isEmailAuth">
      <ion-input labelPlacement="floating" label="Email" placeholder="Managers's Email" formControlName="email" type="email" inputmode="email"></ion-input>
    </ion-item>

    <ng-container *ngIf="!isCodeSent && !isCodeVerified">
      <ion-button *ngIf="!isProcessing" shape="round" (click)="sendCode()" color="darkpurple" class="send-code-btn">Send Code</ion-button>

      <ion-item *ngIf="isProcessing">
        <ion-label>Processing</ion-label>
        <ion-spinner name="dots"></ion-spinner>
      </ion-item>
    </ng-container>

    <ng-container *ngIf="isCodeSent && !isCodeVerified">
      <ion-item>
        <ion-input labelPlacement="floating" label="Code" placeholder="Code"  formControlName="code" type="tel" inputmode="numeric" minlength="4" maxlength="6" pattern="[0-9]+"></ion-input>

        <ion-button slot="end" fill="clear" (click)="sendCode()" [disabled]="otpResendInSec >= 0" size="small">
          <span class="bolden" *ngIf="otpResendInSec >= 0">Resend in {{ otpResendInSec }} secs</span>
          <span class="bolden" *ngIf="otpResendInSec < 0">Resend Code</span>
        </ion-button>
      </ion-item>

      <ion-button *ngIf="!isProcessing" shape="round" (click)="verifyCode()" color="darkpurple">Verify Code</ion-button>

      <ion-item *ngIf="isProcessing">
        <ion-label>Processing</ion-label>
        <ion-spinner name="dots"></ion-spinner>
      </ion-item>
    </ng-container>

    <ng-container *ngIf="isCodeSent && isCodeVerified">
      <ion-item>
        <ion-input #alias labelPlacement="floating"  label="Alias" placeholder="Manager alias e.g dad, mom" formControlName="alias"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input labelPlacement="floating"  label="First Name" placeholder="First Name" formControlName="firstname"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input labelPlacement="floating"  label="Last Name" placeholder="Last Name" formControlName="lastname"></ion-input>
      </ion-item>

      <ion-item>
        <ion-select formControlName="genderidentity" placeholder="Gender Identity" label="Gender Identity" label-placement="floating">
          <ion-select-option *ngFor="let gender of ProfileGenderIdentityEnumValues" [value]="gender">{{ gender }}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item lines="none">
        <ion-input 
          labelPlacement="stacked" 
          type="date" 
          label="Date of Birth" 
          placeholder="Date of Birth"  
          errorText="Date of birth is required"
          min="1920-1-1"
          [max]="todayDate"  
          formControlName="dob"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input labelPlacement="floating" label="Pin" placeholder="Pin"  formControlName="pin" type="password" inputmode="numeric" minlength="4" maxlength="4" pattern="[0-9]+"></ion-input>
      </ion-item>

    </ng-container>

    <ng-container *ngIf="errorMessage">
      <br>

      <ion-text color="danger">
        {{ errorMessage }}
      </ion-text>

      <br>
    </ng-container>

    <ion-button *ngIf="isCodeSent && isCodeVerified" color="darkpurple" [disabled]="!managerForm.valid" (click)="confirm()">Add Manager</ion-button>
  </form>
</ion-content>
