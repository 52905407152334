import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorComponent  implements OnInit {
  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

}
