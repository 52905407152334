<ion-card>
  <img [src]="( school.logoversion ) ? ( staticBaseUrl + '/schools/' + school.id + '/logos/' + school.logoversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/school.svg' )" alt="logo" />
  <ion-card-header>
    <ion-card-title>{{ school.name }}</ion-card-title>
    <ion-card-subtitle>
      <ion-item class="ion-no-padding">
        <ion-img slot="start" src="{{ staticBaseUrl + '/flags/' + school.country.flag }}" class="country-flag"></ion-img>
        <ion-label>{{ school.location }}</ion-label>
      </ion-item>
    </ion-card-subtitle>
  </ion-card-header>

  <ion-card-content>
    <ion-text>
      {{ school.description }}
    </ion-text>
    <ion-list *ngFor="let school_class of school.classes">
      <ion-list-header>
        <ion-label>{{ school_class.class }} {{ school_class.stream }}</ion-label>
      </ion-list-header>

      <ion-item *ngFor="let class_student of school_class.class_students">
        <ion-avatar aria-hidden="true" slot="start">
          <img [src]="( class_student.student.avatarversion ) ? ( staticBaseUrl + '/profiles/' + class_student.student.id + '/avatars/' + class_student.student.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
        </ion-avatar>
        <ion-label>
          <h5>{{ class_student.student.firstname }} {{ class_student.student.lastname }}</h5>
          <ion-note color="medium">Year: {{ class_student.year }}</ion-note>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>
