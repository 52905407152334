import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StockInterface } from '@kiddy-cash/common';
import { ToastService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-select-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
})
export class SelectStockComponent  implements OnInit {
  @Input() stocks: StockInterface[] = [];
  @Input() selectedStock: StockInterface | undefined;
  @Input() title = 'Select Stock';

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<StockInterface>();
  
  staticBaseUrl: string =  environment.staticBaseUrl;

  filteredStocks: StockInterface[] = [];
  workingSelectedStock: StockInterface | undefined;

  constructor(private toastService: ToastService) { }

  ngOnInit() {
    this.filteredStocks = [...this.stocks];
  }
  
  trackStocks(index: number, stock: StockInterface) {
    return stock.id;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }
  
  confirmChanges() {
    if(!this.workingSelectedStock){
      this.toastService.presentToast('Please select a stock first', 'warning')
    } else {
      this.selectionChange.emit(this.workingSelectedStock);
    }
  }
  
  searchbarInput(ev: any) {
    this.filterList(ev.target.value);
  }
  
  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredStocks = [...this.stocks];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase(); 
      this.filteredStocks = this.stocks.filter(stock => {
        return stock.name.toLowerCase().includes(normalizedQuery) || stock.ticker.includes(normalizedQuery);
      });
    }
  }

  radioChange(ev: any) {
    const stock_id = ev.detail.value;

    const selectedStock = this.stocks.find((stock: StockInterface) => stock.id === stock_id);
    if(selectedStock) this.workingSelectedStock = selectedStock;
  }


}
