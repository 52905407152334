import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import {maskitoPhoneOptionsGenerator} from '@maskito/phone';
import metadata from 'libphonenumber-js/mobile/metadata';
import { MaskitoOptions, MaskitoElementPredicate } from "@maskito/core";
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { ToastService } from 'src/app/services';

@Component({
  standalone: false,
  selector: 'integration-dtb-astra-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss'],
})
export class DtbAstraDepositComponent  implements OnInit {
  @Input() account_integration: any;
  staticBaseUrl: string =  environment.staticBaseUrl;

  @Output() depositIntegrationSubmitForm = new EventEmitter<any>();

  mpesaSTKPushForm: FormGroup = new FormGroup({
    phonenumber: new FormControl(),
    amount: new FormControl(),
  });

  readonly maskPredicate: MaskitoElementPredicate = async el => (el as HTMLIonInputElement).getInputElement();
  iso: CountryCode = 'KE'
  public maskitoPhoneOptions: MaskitoOptions = maskitoPhoneOptionsGenerator({
    strict: true,
    countryIsoCode: this.iso, 
    metadata,
  });

  constructor(
    public formBuilder: FormBuilder,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.mpesaSTKPushForm = this.formBuilder.group({
      phonenumber: ['',
        [
          Validators.required,
        ]
      ],
      amount: [0,
        [
          Validators.min(1),
          Validators.required,
        ]
      ],
    })
  }

  submitMpesaSTKPushForm() {
    if (!this.mpesaSTKPushForm.valid) {
      return;
    } else {
      let value = this.mpesaSTKPushForm.value.phonenumber;
      const parsedPhoneNumber = parsePhoneNumber(value)
      if(!parsedPhoneNumber?.isValid()) {
        this.toastService.presentToast('Invalid Phone Number', "danger");
        return;
      }

      const requestData = structuredClone(this.mpesaSTKPushForm.value);
      requestData.phonenumber = parsedPhoneNumber?.number;
      requestData.type = 'KE_DTB_STK_PUSH';
      this.emitOutput(requestData);
    }
  }

  emitOutput(data: any) {
    this.depositIntegrationSubmitForm.emit(data);
  }
}
