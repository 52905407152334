import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AllowanceFrequencyEnum, AllowanceInterface, AllowanceStatusEnum } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common' 

@Component({
  standalone: false,
  selector: 'kc-allowance-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent  implements OnInit {
  @Input() allowance!: AllowanceInterface;
  @Input() isGuardian: boolean = false;
  @Input() isKiddy: boolean = false;
  @Input() allowanceEditForm!: FormGroup

  @Output() allowanceStatusChange = new EventEmitter<any>();
  @Output() editFamilyAllowanceFormSubmit = new EventEmitter<any>();

  staticBaseUrl: string =  environment.staticBaseUrl;

  isEditting: boolean = false;
  AllowanceFrequencyEnumValues = Object.values(AllowanceFrequencyEnum);
  AllowanceStatusEnumValues = Object.values(AllowanceStatusEnum);

  familyAllowanceEditForm: FormGroup = new FormGroup({
    purpose: new FormControl(''),
    amount: new FormControl(''),
    frequency: new FormControl(''),
    start_on: new FormControl(''),
  });

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.familyAllowanceEditForm = this.formBuilder.group({
      purpose: [this.allowance.purpose, 
        [
          Validators.required, 
          Validators.minLength(2), 
          Validators.maxLength(30)
        ]
      ],
      amount: [this.allowance.amount, 
        [
          Validators.required, 
          Validators.min(1), 
        ]
      ],
      frequency: [this.allowance.frequency, 
        [
          Validators.required, 
          Validators.min(1), 
        ]
      ],
      start_on: [this.allowance.start_on.toString(), 
        [
          Validators.required,
        ]
      ],
    })
  }

  startEditing() {
    this.isEditting = true;
  }

  stopEditing() {
    this.isEditting = false;
  }

  submitForm() {
    if (!this.familyAllowanceEditForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.familyAllowanceEditForm.value);
      this.editFamilyAllowanceFormSubmit.emit(requestData);
    }
  }

  allowanceStatusSelectChange(event: any){
    if(this.allowance.status !== event.detail.value) this.allowanceStatusChange.emit(event.detail.value);
  }
}
