import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'stringSpace'
})
export class StringSpacePipe implements PipeTransform {
  formatStr(str: string, n: number) {
    let array = [], start = 0;
    while(start < str.length) {
      array.push(str.slice(start, start + n));
      start += n;
    }
    return array.join(" ");
  }

  reverseString(str: string) {
    return str.split("").reverse().join("");
  }

  transform(value: string, spaces: number): string {
    const newStr = this.reverseString(value);
    const formattedStr = this.formatStr(newStr, spaces);
    return this.reverseString(formattedStr);
  }
}
