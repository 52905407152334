import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FamilyService {
  constructor(private http: HttpClient) { }

  // GET Requests
  getFamilies() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family`);
  }

  getFamiliesKiddies() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/kiddy`);
  }

  getFamiliesIds() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/ids`);
  }

  getFamily(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}`);
  }

  getActiveFamilyAccountIntegration(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/integration`);
  }

  getCountryIntegrationForFamily(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/country_integration`);
  }

  getFamiliesAllowances() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/allowance`);
  }

  getFamilyAllowances(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/allowance`);
  }

  getFamiliesGoals() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/goal`);
  }

  getFamilyGoals(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/goal`);
  }

  getFamiliesInvestments() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/investment`);
  }

  getFamilyInvestments(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/investment`);
  }

  getFamiliesLoans() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/loan`);
  }

  getFamilyLoans(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/loan`);
  }

  getFamiliesTasks() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/task`);
  }

  getFamilyTasks(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/task`);
  }

  getFamiliesTransactions() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/transaction`);
  }

  getFamilyTransaction(family_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/transaction/${transaction_id}`);
  }

  getFamilyTransactions(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/transaction`);
  }

  getFamiliesPaymentCards() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/payment_card`);
  }

  getFamilyPaymentCards(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/payment_card`);
  }

  getFamilyKiddyAccount(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}`);
  }

  getFamilyKiddyAccountAllowance(family_id: string, kiddy_account_id: string, allowance_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/allowance/${allowance_id}`);
  }

  getFamilyKiddyAccountAllowances(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/allowance`);
  }

  getFamilyKiddyAccountGoal(family_id: string, kiddy_account_id: string, goal_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/goal/${goal_id}`);
  }

  getFamilyKiddyAccountGoals(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/goal`);
  }

  getFamilyKiddyAccountInvestment(family_id: string, kiddy_account_id: string, investment_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/investment/${investment_id}`);
  }

  getFamilyKiddyAccountInvestments(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/investment`);
  }

  getFamilyKiddyAccountLoan(family_id: string, kiddy_account_id: string, loan_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/loan/${loan_id}`);
  }

  getFamilyKiddyAccountLoans(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/loan`);
  }

  getFamilyKiddyAccountTask(family_id: string, kiddy_account_id: string, task_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/task/${task_id}`);
  }

  getFamilyKiddyAccountTasks(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/task`);
  }

  getFamilyKiddyAccountTransaction(family_id: string, kiddy_account_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/transaction/${transaction_id}`);
  }

  getFamilyKiddyAccountTransactions(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/transaction`);
  }

  getFamilyKiddyAccountPaymentCard(family_id: string, kiddy_account_id: string, payment_card_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/payment_card/${payment_card_id}`);
  }

  getFamilyKiddyAccountPaymentCards(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/payment_card`);
  }

  getFamiliesAllowancesGenerated() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/allowance/generated`);
  }

  getFamilyAllowancesGenerated(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/allowance/generated`);
  }

  getFamilyKiddyAccountAllowancesGenerated(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/allowance/generated`);
  }

  getFamiliesGoalsGenerated() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/goal/generated`);
  }

  getFamilyGoalsGenerated(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/goal/generated`);
  }

  getFamilyKiddyAccountGoalsGenerated(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/goal/generated`);
  }

  getFamiliesInvestmentsGenerated() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/investment/generated`);
  }

  getFamilyInvestmentsGenerated(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/investment/generated`);
  }

  getFamilyKiddyAccountInvestmentsGenerated(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/investment/generated`);
  }

  getFamiliesLoansGenerated() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/loan/generated`);
  }

  getFamilyLoansGenerated(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/loan/generated`);
  }

  getFamilyKiddyAccountLoansGenerated(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/loan/generated`);
  }

  getFamiliesPaymentCardsGenerated() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/payment_card/generated`);
  }

  getFamilyPaymentCardsGenerated(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/payment_card/generated`);
  }

  getFamilyKiddyAccountPaymentCardsGenerated(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/payment_card/generated`);
  }

  getFamiliesTasksGenerated() {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/task/generated`);
  }

  getFamilyTasksGenerated(family_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/task/generated`);
  }

  getFamilyKiddyAccountTasksGenerated(family_id: string, kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/task/generated`);
  }

  // POST Requests
  createFamily(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family`, data);
  }

  createFamilyAccountIntegration(family_id: string, data?: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/integration`, data);
  }

  depositIntoFamily(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/deposit`, data);
  }

  withdrawFromFamily(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/withdraw`, data);
  }

  transactionForFamily(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/transaction`, data);
  }

  reviewTransactionForFamily(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/transaction/review`, data);
  }

  addFamilyKiddyAccounts(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account`, data);
  }

  addFamilyManagers(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/managers`, data);
  }

  depositIntoFamilyKiddyAccount(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/deposit`, data);
  }

  loanIntoFamilyKiddyAccount(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/loan`, data);
  }

  allowanceForFamilyKiddyAccount(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/allowance`, data);
  }

  goalForFamilyKiddyAccount(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/goal`, data);
  }

  investmentForFamilyKiddyAccount(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/investment`, data);
  }

  taskForFamilyKiddyAccount(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/task`, data);
  }

  paymentCardForFamilyKiddyAccount(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/payment_card`, data);
  }

  postFamiliesAllowancesGenerated(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/allowance/generated`, data);
  }

  postFamilyAllowancesGenerated(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/allowance/generated`, data);
  }

  postFamilyKiddyAccountAllowancesGenerated(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/allowance/generated`, data);
  }

  postFamiliesGoalsGenerated(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/goal/generated`, data);
  }

  postFamilyGoalsGenerated(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/goal/generated`, data);
  }

  postFamilyKiddyAccountGoalsGenerated(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/goal/generated`, data);
  }

  postFamiliesInvestmentsGenerated(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/investment/generated`, data);
  }

  postFamilyInvestmentsGenerated(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/investment/generated`, data);
  }

  postFamilyKiddyAccountInvestmentsGenerated(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/investment/generated`, data);
  }

  postFamiliesLoansGenerated(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/loan/generated`, data);
  }

  postFamilyLoansGenerated(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/loan/generated`, data);
  }

  postFamilyKiddyAccountLoansGenerated(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/loan/generated`, data);
  }

  postFamiliesTasksGenerated(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/task/generated`, data);
  }

  postFamilyTasksGenerated(family_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/task/generated`, data);
  }

  postFamilyKiddyAccountTasksGenerated(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/task/generated`, data);
  }

  //PUT Requests
  updateFamily(family_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}`, data);
  }

  updateFamilyTask(family_id: string, task_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/task/${task_id}`, data);
  }

  updateFamilyTaskStatus(family_id: string, task_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/task/${task_id}/status`, data);
  }

  updateFamilyTransactionStatus(family_id: string, transaction_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/transaction/${transaction_id}/status`, data);
  }
  
  updateFamilyKiddyAccount(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}`, data);
  }

  updateFamilyKiddyAccountAllowance(family_id: string, kiddy_account_id: string, allowance_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/allowance/${allowance_id}`, data);
  }

  updateFamilyKiddyAccountGoal(family_id: string, kiddy_account_id: string, goal_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/goal/${goal_id}`, data);
  }

  updateFamilyKiddyAccountInvestment(family_id: string, kiddy_account_id: string, investment_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/investment/${investment_id}`, data);
  }

  updateFamilyKiddyAccountLoan(family_id: string, kiddy_account_id: string, loan_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/loan/${loan_id}`, data);
  }

  updateFamilyKiddyAccountTasks(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/task`, data);
  }

  updateFamilyKiddyAccountTransactions(family_id: string, kiddy_account_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/transaction`, data);
  }

  updateFamilyPaymentCard(family_id: string, payment_card_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/family/${family_id}/payment_card/${payment_card_id}`, data);
  }

  // Delete Requests
  deleteFamily(family_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}`);
  }

  removeFamilyManagers(family_id: string, data: any) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}/managers`, {
      body: data
    });
  }

  deleteFamilyKiddyAccount(family_id: string, kiddy_account_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}`);
  }

  deleteFamilyKiddyAccountAllowance(family_id: string, kiddy_account_id: string, allowance_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/allowance/${allowance_id}`);
  }

  deleteFamilyKiddyAccountGoal(family_id: string, kiddy_account_id: string, goal_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/goal/${goal_id}`);
  }

  deleteFamilyKiddyAccountInvestment(family_id: string, kiddy_account_id: string, investment_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/investment/${investment_id}`);
  }

  deleteFamilyKiddyAccountLoan(family_id: string, kiddy_account_id: string, loan_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/loan/${loan_id}`);
  }

  deleteFamilyKiddyAccountPaymentCard(family_id: string, kiddy_account_id: string, payment_card_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/payment_card/${payment_card_id}`);
  }

  deleteFamilyKiddyAccountTask(family_id: string, kiddy_account_id: string, task_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/family/${family_id}/kiddy/account/${kiddy_account_id}/task/${task_id}`);
  }

}
