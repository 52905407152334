import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  constructor(private http: HttpClient) { }

  // Data Requests i.e publicly cacheable
  searchBusinesses(country_id: number, business_name: string) {
    return this.http.get<any>(`${environment.dataBaseUrl}/business/country/${country_id}/${business_name}`);
  }

  getBusinessInfo(business_id: string) {
    return this.http.get<any>(`${environment.dataBaseUrl}/business/${business_id}`);
  }

}
