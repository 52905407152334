<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()">Cancel</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirmChanges()">Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar (ionInput)="searchbarInput($event)" debounce="300"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-radio-group (ionChange)="radioChange($event)">
    <ion-list id="modal-list" [inset]="true" *ngIf="schools.length > 0">
      <ion-item *ngFor="let school of schools; trackBy: trackSchools">
        <ion-thumbnail slot="start" class="ion-margin-end">
          <ion-img src="{{ school.logoversion ? ( staticBaseUrl + '/schools/' + school.id + '/logos/' + school.logoversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/school.svg') }}" class="school-logo"></ion-img>
        </ion-thumbnail>

        <ion-radio [value]="school.id">
          <ion-label>{{ school.name }}</ion-label>
          <ion-note color="medium">{{ school.location }}</ion-note>
        </ion-radio>

        <br />
      </ion-item>
    </ion-list>
  </ion-radio-group>
</ion-content>