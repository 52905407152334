
<ion-row class="ion-align-items-center ion-justify-content-center">
  <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">
    <ion-accordion-group [multiple]="true" [value]="['stk_push']">
      <ion-accordion value="stk_push">
        <ion-item slot="header" color="light">
          <ion-label>Withdraw to Mpesa Phonenumber</ion-label>
        </ion-item>
        <div class="ion-padding" slot="content">
          <form [formGroup]="withdrawIntegrationForm" (ngSubmit)="submitForm()">
            <ion-item fill="outline" class="transparent-background" lines="none">
              <ion-input
                [clearInput]="true"
                placeholder="Phone number"
                formControlName="phonenumber"
                type="tel"
                [maskito]="maskitoPhoneOptions"
                [maskitoElement]="maskPredicate"            
                inputmode="numeric"
            ></ion-input>
            </ion-item>
  
            <br />
  
            <ion-item fill="outline" class="transparent-background" lines="none">
              <ion-input
                type="number"
                placeholder="Amount"
                formControlName="amount"
                inputmode="numeric"
                min="1"
              ></ion-input>
            </ion-item>
  
            <ion-button
              expand="full"
              color="darkpurple"
              type="submit"
              [disabled]="!withdrawIntegrationForm.valid">
              Withdraw
            </ion-button>
          </form>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-col>
</ion-row>