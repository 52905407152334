<ion-segment>
  <ion-segment-button value="custodians" content-id="custodians" *ngIf="hasKiddyRole">
    <ion-label>Select Custodians</ion-label>
  </ion-segment-button>
  <ion-segment-button value="request_custodians" content-id="request_custodians" *ngIf="hasKiddyRole">
    <ion-label>Request Custodians</ion-label>
  </ion-segment-button>
  <ion-segment-button value="kiddies" content-id="kiddies" *ngIf="hasCustodianRole">
    <ion-label>Select Kiddies</ion-label>
  </ion-segment-button>
  <ion-segment-button value="request_kiddies" content-id="request_kiddies" *ngIf="hasCustodianRole">
    <ion-label>Request Kiddies</ion-label>
  </ion-segment-button>
</ion-segment>
<ion-segment-view>
  <ion-segment-content id="custodians" *ngIf="hasKiddyRole">
    <ion-item>
      <ion-select 
        label="Select Custodians" 
        label-placement="floating" 
        [multiple]="true"
        (ionChange)="handleCustodianChange($event)">
        <ion-select-option *ngFor="let custodian_profile of custodian_profiles" [value]="custodian_profile.id">
          {{ custodian_profile.firstname }} {{ custodian_profile.lastname }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-segment-content>
  <ion-segment-content id="request_custodians" *ngIf="hasKiddyRole">
    <div>
      <ion-item >
        <ion-select
          label="Number of Managers"
          aria-label="Managers"
          placeholder="0"
          (ionChange)="handleManagerChange($event)"
          cancelText="Cancel Choice"
          interface="action-sheet"
          color="primary"
        >
          <ion-select-option value="0">0</ion-select-option>
          <ion-select-option value="1">1</ion-select-option>
          <ion-select-option value="2">2</ion-select-option>
          <ion-select-option value="3">3</ion-select-option>
          <ion-select-option value="4">4</ion-select-option>
          <ion-select-option value="5">5</ion-select-option>
          <ion-select-option value="6">6</ion-select-option>
          <ion-select-option value="7">7</ion-select-option>
          <ion-select-option value="8">8</ion-select-option>
          <ion-select-option value="9">9</ion-select-option>
          <ion-select-option value="10">10</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-grid>
        <ion-row class="ion-margin-top ion-justify-content-center" *ngFor="let manager of managers; let i = index" (click)="openManagerModal(manager)">
          <ion-col class="manager-section" size="11">
            <ion-text>{{ manager.firstname ? manager.firstname : 'Manager'}} {{ manager.lastname ? manager.lastname : i + 1 }}</ion-text>
            <div class="edit-div">
              <ion-text class="edit-btn">Edit</ion-text>
              <ion-icon name="checkmark-circle" class="checkmark" *ngIf="manager.valid"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-segment-content>
  <ion-segment-content id="kiddies" *ngIf="hasCustodianRole">
    <ion-item>
      <ion-select 
        label="Select Kiddies" 
        label-placement="floating" 
        [multiple]="true"
        (ionChange)="handleKiddyChange($event)">
        <ion-select-option *ngFor="let kiddy_profile of kiddy_profiles" [value]="kiddy_profile.id">
          {{ kiddy_profile.firstname }} {{ kiddy_profile.lastname }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-segment-content>
  <ion-segment-content id="request_kiddies" *ngIf="hasCustodianRole">
    <div>
      <ion-item >
        <ion-select
          label="Number of Children"
          aria-label="Children"
          placeholder="0"
          (ionChange)="handleChildrenChange($event)"
          cancelText="Cancel Choice"
          interface="action-sheet"
          color="primary"
        >
          <ion-select-option value="0">0</ion-select-option>
          <ion-select-option value="1">1</ion-select-option>
          <ion-select-option value="2">2</ion-select-option>
          <ion-select-option value="3">3</ion-select-option>
          <ion-select-option value="4">4</ion-select-option>
          <ion-select-option value="5">5</ion-select-option>
          <ion-select-option value="6">6</ion-select-option>
          <ion-select-option value="7">7</ion-select-option>
          <ion-select-option value="8">8</ion-select-option>
          <ion-select-option value="9">9</ion-select-option>
          <ion-select-option value="10">10</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-grid>
        <ion-row  class="ion-margin-top ion-justify-content-center" *ngFor="let child of children; let i = index"  (click)="openChildModal(child)">
          <ion-col class="child-section" size="11">
            <ion-text>{{ child.firstname ? child.firstname : 'Child'}} {{ child.lastname ? child.lastname : i + 1 }}</ion-text>
            <div class="edit-div">
              <ion-text class="edit-btn">Edit</ion-text>
              <ion-icon name="checkmark-circle" class="checkmark" *ngIf="child.valid"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-segment-content>
</ion-segment-view>