<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()">Cancel</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirmChanges()">Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar (ionInput)="searchbarInput($event)" debounce="300"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-radio-group (ionChange)="radioChange($event)">
    <ion-list id="modal-list" [inset]="true" *ngIf="businesses.length > 0">
      <ion-item *ngFor="let business of businesses; trackBy: trackBusinesses">
        <ion-thumbnail slot="start" class="ion-margin-end">
          <ion-img src="{{ business.logoversion ? ( staticBaseUrl + '/businesses/' + business.id + '/logos/' + business.logoversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/business.svg') }}" class="business-logo"></ion-img>
        </ion-thumbnail>

        <ion-radio [value]="business.id">
          <ion-label>{{ business.name }}</ion-label>
          <ion-note color="medium">{{ business.total_followers | number }} Followers</ion-note>
        </ion-radio>

        <br />
      </ion-item>
    </ion-list>
  </ion-radio-group>
</ion-content>