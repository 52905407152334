import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, Observable, retry, throwError, timer } from 'rxjs';

const MAX_OF_RETRY = 1;
const RETRY_DELAY = 1000;

export const networkErrorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req)
    .pipe(
      retry({
        count: MAX_OF_RETRY,
        delay: (error: HttpErrorResponse, retryAttempt: number): Observable<number> => {
          // if maximum number of retries have been met
          // or response is a status code we don't wish to retry, throw error
          if (retryAttempt > MAX_OF_RETRY || error.status !== 0) {
            return throwError(() => error);
          }

          return timer(retryAttempt * RETRY_DELAY);
        },
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => {
          if(error.status === 0) {
            const modifiedError = new HttpErrorResponse({
              error: {
                message: 'Network error. Please try again later.',
              },
              headers: error.headers,
              url: error.url as string,
              status: error.status,
              statusText: error.statusText,
            })
            return modifiedError;
          } else {
            return error;
          }
        });
      })
    );
};
