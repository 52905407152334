<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" [strong]="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-grid>

    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">
        <ion-text color="amber">
          <h3>Review Transaction</h3>
        </ion-text>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size-xl="9" size-lg="9" size-sm="12" size="12">

        <ion-grid>
          <ion-row *ngIf="from_account">
            <ion-col>
              <ion-text>From Account</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ from_account.name }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="from_account">
            <ion-col>
              <ion-text>Account No:</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ from_account.number }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="from_account">
            <ion-col>
              <ion-text>Account Balance</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ from_account.balance | number }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="from_account">
            <ion-col>
              <ion-text><hr></ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="to_account">
            <ion-col>
              <ion-text>To Account</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ to_account.name }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="requestData.accounttype">
            <ion-col>
              <ion-text>To Account Type</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ requestData.accounttype }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="requestData.to_account_number">
            <ion-col>
              <ion-text>Account No:</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ requestData.to_account_number }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="requestData.amount">
            <ion-col>
              <ion-text>Amount</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ requestData.amount | number }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="requestData.purpose">
            <ion-col>
              <ion-text>Purpose</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ requestData.purpose }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="transaction_code">
            <ion-col>
              <ion-text>Transaction Code</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ transaction_code.code }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-text>Fee</ion-text>
            </ion-col>
            <ion-col>
              <ion-text>{{ fee | number }}</ion-text>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col class="ion-no-padding ion-text-center">
              <ion-button class="ion-margin ion-padding" (click)="confirm()" shape="round" color="success">Transact</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>