import { Component, Input, Output, EventEmitter } from '@angular/core';
import type { OnInit } from '@angular/core';
import { CountryInterface, BusinessInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';
import { LoadingService, BusinessService, ToastService } from 'src/app/services';

@Component({
  standalone: false,
  selector: 'kc-select-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class SelectBusinessComponent  implements OnInit {
  @Input() selectedCountry!: CountryInterface;
  @Input() selectedBusiness: BusinessInterface | undefined;
  @Input() title = 'Select Business';

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<BusinessInterface>();

  staticBaseUrl: string =  environment.staticBaseUrl;

  businesses: BusinessInterface[] = [];
  workingSelectedBusiness: BusinessInterface | undefined;

  constructor(
    private businessService: BusinessService,
    private loadingService: LoadingService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {

  }
  
  trackBusinesses(index: number, business: BusinessInterface) {
    return business.id;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }
  
  confirmChanges() {
    if(!this.workingSelectedBusiness){
      this.toastService.presentToast('Please select a business first', 'warning')
    } else {
      this.selectionChange.emit(this.workingSelectedBusiness);
    }
  }
  
  searchbarInput(ev: any) {
    this.filterList(ev.target.value);
  }
  
  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (!searchQuery) {
      this.businesses = [];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase(); 
      this.queryBusinesses(normalizedQuery);
    }
  }

  radioChange(ev: any) {
    const business_id = ev.detail.value;

    const selectedBusiness = this.businesses.find((business: BusinessInterface) => business.id === business_id);
    if(selectedBusiness) this.workingSelectedBusiness = selectedBusiness;
  }

  queryBusinesses(searchname: string) {
    const country_id = this.selectedCountry.id as number;

    this.loadingService.present();
    this.businessService.searchBusinesses(country_id, searchname).subscribe((res) => {
      this.businesses = res.data.businesses;
      this.loadingService.dismiss();
    },
    (err: any) => {
      this.toastService.presentToast(err.error.message, 'danger');
      this.loadingService.dismiss();
    })
  }

}
