import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  standalone: false,
  selector: 'kc-document-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class DocumentViewerComponent  implements OnInit {
  @Input() title = 'Document';

  base64EncodedDocument!: string;
  mimetype!: string;
  data: SafeResourceUrl = this.getSanitize(`data:${this.mimetype};base64,${this.base64EncodedDocument}`);
  height: string = this. mimetype === 'application/pdf' ? '400px' : '100%'

  constructor(
    private modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.data = this.getSanitize(`data:${this.mimetype};base64,${this.base64EncodedDocument}`)
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }

  getSanitize(string: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(string);
  }
}
