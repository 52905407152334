<ion-item lines="none" class="task-main-item" routerDirection="root" [routerLink]="route">
  <ion-row class="tasks-row">
    <ion-col size="12" class="task-ion-body">
      <div>
        <ion-item lines="none" class="task-ion-item"
          ><ion-text class="task-title" color="primary">{{
            allowance.purpose
          }}</ion-text></ion-item
        >

        <ion-item lines="none" class="task-ion-item"
          ><ion-text class="task-body" color="amber"
            >{{ allowance.to_account.kiddy?.firstname }}
            {{ allowance.to_account.kiddy?.lastname }}</ion-text
          ></ion-item
        >
        <ion-item lines="none" class="task-ion-item">
          <ion-text class="task-date" color="primary"
            ><span class="bolden">{{ allowance.frequency }}</span> from
            {{ allowance.start_on | date : "mediumDate" }}</ion-text
          ></ion-item
        >
      </div>
      <div class="reward-div">
        <ion-item lines="none" class="task-ion-item"
          ><ion-text class="reward-text">Amount</ion-text></ion-item
        >

        <ion-item lines="none" class="task-ion-item">
          <ion-text class="reward-amount">
            {{ allowance.from_account.country.currency.code }}
            {{ allowance.amount | number:'1.0-2' }}
          </ion-text>
        </ion-item>
        <ion-item lines="none" class="task-ion-item">
          <ion-button class="status-button" expand="block" [color]="getStatusColor(allowance.status)" size="small">{{ allowance.status }}</ion-button>
        </ion-item>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
