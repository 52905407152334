import { Component, Input, OnInit } from '@angular/core';
import { PaymentCardInterface } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'kc-payment-card-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class PaymentCardListItemComponent  implements OnInit {
  @Input() payment_card!: PaymentCardInterface;
  @Input() route: (string | undefined)[] = [];

  constructor() { }

  ngOnInit() {}

}
