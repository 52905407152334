<ion-header class="ion-no-border">
  <ion-toolbar color="background">
    <div class="exit-div"><ion-icon name="arrow-back" class="back-icon" (click)="cancel()"></ion-icon>
      <ion-title color="primary">Update your profile</ion-title></div>
    <ion-buttons slot="end">
      <ion-button type="submit" shape="round" [disabled]="!profileUpdateForm.valid" color="amber" class="test" (click)="submitForm()">
        <ion-text color="light-text">Update</ion-text></ion-button>
    </ion-buttons>
    
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding"  color="background">
  <ion-grid >
    <ion-row class="ion-align-items-center ion-justify-content-center" >
      <ion-col size-xl="12" size-lg="6" size-sm="6" size="12" >
        <form [formGroup]="profileUpdateForm" (ngSubmit)="submitForm()">
          <ion-item class="transparent-background" [button]="true" [detail]="false" id="select-country-profile-page">
            <ion-img *ngIf="userCountry" src="{{staticBaseUrl + '/flags/' + userCountry.flag}}" class="country-flag"></ion-img>
            <ion-text *ngIf="userCountry" class="ion-padding-horizontal" color="primary">{{ userCountry.name }}</ion-text>
            <ion-text *ngIf="!userCountry" class="ion-padding-horizontal" color="primary">Select Country</ion-text>
            <ion-icon slot="end" name="chevron-down-outline"></ion-icon>           
          </ion-item>

          <ion-list class="list-modal">
            <ion-item color="background">
              <ion-label position="floating" color="primary">First name</ion-label>
              <ion-input
                type="text"
            
                errorText="Firstname is required"
                counter="true"
                minlength="2"
                maxlength="30"
                required="true"
                formControlName="firstname"
                color="primary"
                class="custom" 
              ></ion-input>
            </ion-item>
          </ion-list>

          <ion-list class="list-modal">
            <ion-item color="background">
              <ion-label position="floating" color="primary">Last name</ion-label>
              <ion-input
                type="text"
                errorText="Lastname is required"
                counter="true"
                minlength="2"
                maxlength="30"
                required="true"
                formControlName="lastname"
                class="custom" 
              ></ion-input>
            </ion-item>
          </ion-list>

          <ion-list class="list-modal">
            <ion-item color="background">
              <ion-label position="floating" color="primary">Gender Identity</ion-label>
              <ion-select formControlName="genderidentity" placeholder="Gender Identity"  class="custom" >
                <ion-select-option *ngFor="let gender of ProfileGenderIdentityEnumValues" [value]="gender">{{ gender }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>

          <ion-list class="list-modal">
            <ion-item color="background">
              <ion-label position="floating" color="primary">Date of Birth</ion-label>
              <ion-input
                type="date"
                errorText="Date of birth is required"
                min="1920-1-1"
                max="{{ maxDate }}"              
                required="true"
                formControlName="dob"
                class="custom" 
              ></ion-input>
            </ion-item>
          </ion-list>

          <ion-list class="list-modal">
            <ion-item color="background">
              <ion-label position="stacked" color="primary">Choose a profile picture</ion-label>
              <input #fileInput type="file" accept="image/png, image/jpeg, image/jpg">
            </ion-item>
          </ion-list>

          <div #cropperContainer class="cropper-container"></div>

          <ion-list class="list-modal">
            <ion-item color="background">
              <ion-label position="floating" color="primary">Bio</ion-label>
              <ion-textarea
                errorText="Invalid bio"
                counter="true"
                minlength="2"
                maxlength="400"
                formControlName="bio"
                class="custom" 
              ></ion-textarea>
            </ion-item>
          </ion-list>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-modal trigger="select-country-profile-page" #modal *ngIf="countries.length">
  <ng-template>
    <kc-select-country
      class="ion-page"
      title="Select Your Country"
      [countries]="countries"
      [selectedCountry]="selectedCountry"
      (selectionChange)="countrySelectionChanged($event); modal.dismiss()"
      (selectionCancel)="modal.dismiss()"
    ></kc-select-country>
  </ng-template>
</ion-modal>
