import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, PermissionStatus as PushNotificationPermissionStatus } from '@capacitor/push-notifications';
import { AlertController } from '@ionic/angular';
import { UserInterface, DeviceInterface, AppEnum } from '@kiddy-cash/common';
import { AuthService } from '../auth/auth.service';
import { ToastService } from './toast.service';
import { getMessaging, getToken, onMessage, isSupported, deleteToken } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { filter, take } from 'rxjs/operators';
import { Messaging } from '@angular/fire/messaging';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { GetResult, Preferences } from '@capacitor/preferences';
import { NotificationService } from './notification.service';
import { Badge, PermissionStatus as BadgePermissionStatus } from '@capawesome/capacitor-badge';
import type { PermissionState } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  user: UserInterface | null | undefined;
  isPushNotificationsAvailable = Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('PushNotifications');
  messaging!: Messaging;
  private hasBadgePermissionsGranted: boolean = false;

  constructor(
    private alertController: AlertController,
    private toastService: ToastService,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) { }

  public async setUp() {
    if(this.isPushNotificationsAvailable) {
      await this.addListenersNative()
    } else {
      this.messaging = getMessaging() 
      this.listenWeb()
    }

    this.authService._$user
      .pipe(filter(user => user !== null && user !== undefined), take(1))
      .subscribe(async (user: UserInterface | null | undefined) => {
      this.user = user;
      if(user){
        if(this.isPushNotificationsAvailable && await this.shouldPromptNative()){
          this.prePrompt();
        } else if(await isSupported() && Notification.permission !== "denied" && Notification.permission !== "granted" && await this.getFcmToken() === null) {
          this.prePrompt();
        } else if (await isSupported() && Notification.permission !== "denied" && Notification.permission !== "granted") {
          this.isPushNotificationsAvailable ? this.registerNotificationsNative() : this.registerNotificationsWeb(); 
        } else if(await isSupported() && Notification.permission === "granted") {
          // already granted, let's make sure user is subscribed
          this.isPushNotificationsAvailable ? this.registerNotificationsNative() : this.registerNotificationsWeb();
        }
      }
    });

    if(await this.isBadgeSupported()){
      const permissionState: BadgePermissionStatus = await this.checkBadgePermissions();
      const display: PermissionState = permissionState.display;
      if (display === 'prompt' || display === 'prompt-with-rationale') {
        const badgePermissionStatus: BadgePermissionStatus = await this.requestBadgePermissions();
        if (badgePermissionStatus.display === 'granted') this.hasBadgePermissionsGranted = true;
      }
      if (display === 'granted') this.hasBadgePermissionsGranted = true;
    }
  }

  registerNotificationsWeb() {
    getToken(this.messaging, 
     { vapidKey: environment.firebase.vapidKey}).then(
       async (currentToken) => {
         if (currentToken) {      
          this.registerToken(currentToken);
         } else {
           console.warn('No registration token available. Request permission to generate one.');
         }
     }).catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
    });
  }

  async unregisterNotificationsWeb() {
    try {
      await deleteToken(this.messaging);
    } catch (err) {
      console.error(err)
    }
  }

  listenWeb() {
    onMessage(this.messaging, (payload) => {
      const notificationTitle = payload?.notification?.title || 'Kiddy Cash';
      const notificationOptions = {
        body: payload?.notification?.body,
      };

      new Notification(notificationTitle, notificationOptions);
    });
  }

  shouldPromptNative = async (): Promise<boolean> => {
    const permStatus: PushNotificationPermissionStatus = await PushNotifications.checkPermissions();
    // permStatus.receive is either 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
    if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') {
      return true
    }

    return false;
  }

  async prePrompt(){
    const alert = await this.alertController.create({
      header: 'Notifications',
      subHeader: 'Important message',
      message: 'To best serve you, we need your permission to send some notifications about your account and any transactions done through it.',
      buttons: [{
        text: 'OK',
        role: 'confirm',
        handler: () => { 
          this.isPushNotificationsAvailable ? this.registerNotificationsNative() : this.registerNotificationsWeb(); 
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => { 
          this.toastService.presentToast("Okay, but we can't keep you up to date unless you permit us to notify you in a timely manner", 'warning');
        }
      }],
    });

    await alert.present();
  }

  addListenersNative = async () => {
    await PushNotifications.addListener('registration', async token => {  
      this.registerToken(token.value);
    });
  
    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });
  
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
    });
  
    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
  }

  registerNotificationsNative = async () => {
    let permStatus: PushNotificationPermissionStatus = await PushNotifications.checkPermissions();
  
    if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') {
      permStatus = await PushNotifications.requestPermissions();
    }
  
    if (permStatus.receive !== 'granted') {
      console.error('User denied permissions!');
      return;
    }

    await PushNotifications.register();
  }

  unregisterNotificationsNative = async () => {
    try {
      await PushNotifications.unregister();
    } catch (err) {
      console.error(err)
    }
  }
  
  getDeliveredNotificationsNative = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }

  registerToken = async (fcm_token: string) => {
    const device_id: DeviceId = await Device.getId();
    const info: DeviceInfo = await Device.getInfo();

    const data: DeviceInterface = {
      device_id: device_id.identifier,
      info: info,
      fcm_token: fcm_token,
      projectId: environment.firebase.projectId,
      app: AppEnum.App,
    }
    this.notificationService.registerDevice(data).subscribe((res: any) => {
      this.storeFcmToken(fcm_token)
    },
    async (err: any) => {
      await Preferences.remove({key: 'fcm_token'})
    })
  }

  unregisterToken = async () => {
    this.isPushNotificationsAvailable ? await this.unregisterNotificationsNative() : await this.unregisterNotificationsWeb(); 

    const storedFcmToken: GetResult = await Preferences.get({ key: 'fcm_token' });
    const fcmToken = storedFcmToken.value;

    return this.notificationService.deleteDevices({fcm_token: fcmToken}).subscribe(async (res: any) => {
      return await Preferences.remove({key: 'fcm_token'})
    },
    async (error: any) => {
      console.error('error deleting fcm_tokens', error)
    })
  }

  async getFcmToken(): Promise<string | null> {
    const storedFcmToken:GetResult = await Preferences.get({ key: 'fcm_token' });
    const fcmToken = storedFcmToken.value;

    return fcmToken;
  }

  async storeFcmToken(token: string) {
    await Preferences.set({
      key: 'fcm_token',
      value: token
    });
  }

  async isBadgeSupported() {
    if(!Capacitor.isNativePlatform()) return false
    const result = await Badge.isSupported();
    return result.isSupported;
  }

  async checkBadgePermissions() {
    const result = await Badge.checkPermissions();
    return result
  }

  async requestBadgePermissions() {
    const result = await Badge.requestPermissions();
    return result
  }

  isBadgePermissionsGranted(): boolean {
    if(!Capacitor.isNativePlatform()) return false
    return this.hasBadgePermissionsGranted;
  }

}
