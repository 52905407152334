import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard, KiddyRoleGuard, FamilyRoleGuard } from './core/guards';

const routes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    title: "Kiddy Cash",
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'about',
    title: "About Us",
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'terms',
    title: "Terms of Service",
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    title: "Privacy Policy",
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'stock',
    title: "Stocks",
    loadChildren: () => import('./pages/stock/stock.module').then( m => m.StockPageModule)
  },
  {
    path: 'login',
    title: "Secure Login",
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'sign-up',
    title: "Sign Up",
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'dashboard',
    title: "Kiddy Cash",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'forgot-pin',
    title: "Forgot Pin",
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/forgot-pin/forgot-pin.module').then( m => m.ForgotPinPageModule)
  },
  {
    path: 'profile',
    title: "My Profile",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'notifications',
    title: "Notifications",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'family',
    title: "Kiddy Cash | Family",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/family/family.module').then( m => m.FamilyPageModule)
  },
  {
    path: 'families',
    title: "Kiddy Cash | Families",
    canActivate: [AuthGuard, FamilyRoleGuard],
    loadChildren: () => import('./pages/families/families.module').then( m => m.FamiliesPageModule)
  },
  {
    path: 'kiddy',
    title: "Kiddy Cash | Kiddy",
    canActivate: [AuthGuard, KiddyRoleGuard],
    loadChildren: () => import('./pages/kiddy/kiddy.module').then( m => m.KiddyPageModule)
  },
  {
    path: 'kiddy-business',
    title: "Kiddy Cash | Kiddy Business",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/kiddy-business/kiddy-business.module').then( m => m.KiddyBusinessPageModule)
  },
  {
    path: 'kiddy-businesses',
    title: "Kiddy Cash | Kiddy Businesses",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/kiddy-businesses/kiddy-businesses.module').then( m => m.KiddyBusinessesPageModule)
  },
  {
    path: 'schools',
    title: "Kiddy Cash | Schools",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/schools/schools.module').then( m => m.SchoolsPageModule)
  },
  {
    path: 'school',
    title: "Kiddy Cash | School",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/school/school.module').then( m => m.SchoolPageModule)
  },
  {
    path: 'business',
    title: "Kiddy Cash | Business",
    loadChildren: () => import('./pages/business/business.module').then( m => m.BusinessPageModule)
  },
  {
    path: '**',
    title: "Kiddy Cash | Page Not Found",
    loadChildren: () => import('./pages/not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
