<ion-card>
  <ion-card-header>
    <ion-card-title>{{ family.name }}</ion-card-title>
    <ion-card-subtitle>{{ family.country.currency.symbol }} {{ family.balance | number:'1.0-2' }}</ion-card-subtitle>
  </ion-card-header>

  <ion-card-content>
    <ion-list>
      <ion-list-header *ngIf="family.kiddy_accounts?.length">
        <ion-label>Kiddy Accounts</ion-label>
      </ion-list-header>

      <ion-item *ngFor="let kiddy_account of family.kiddy_accounts">
        <ion-avatar aria-hidden="true" slot="start">
          <img [src]="( kiddy_account.kiddy?.avatarversion ) ? ( staticBaseUrl + '/profiles/' + kiddy_account.kiddy?.id + '/avatars/' + kiddy_account.kiddy?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg' )"/>
        </ion-avatar>
        <ion-label>
          <h4>{{ kiddy_account.kiddy?.firstname }} {{ kiddy_account.kiddy?.lastname }}</h4>
          <ion-note color="medium">{{ kiddy_account.country.currency.symbol }} {{ kiddy_account.balance | number:'1.0-2' }}</ion-note>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>
