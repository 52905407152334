import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransactionInterface, TransactionStatusEnum } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'kc-transaction-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class TransactionCardComponent  implements OnInit {
  @Input() transaction!: TransactionInterface;
  @Input() isGuardian: boolean = false;
  @Input() isKiddy: boolean = false;
  @Input() canApprove: boolean = false;

  @Output() transactionStatusChange = new EventEmitter<any>();

  TransactionStatusEnum = TransactionStatusEnum;
  TransactionStatusEnumValues = Object.values(TransactionStatusEnum);

  constructor() { }

  ngOnInit() {}

  transactionStatusSelectChange(event: any){
    if(this.transaction.status !== event.detail.value) this.transactionStatusChange.emit(event.detail.value);
  }

}
