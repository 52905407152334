import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AccountCardComponent } from './card/card.component';

@NgModule({
  declarations: [
    AccountCardComponent,
  ],
  exports: [
    AccountCardComponent,
  ],
  imports: [
    CommonModule,
    DatePipe, 
    IonicModule, 
    RouterModule,
  ]
})
export class AccountModule { }
