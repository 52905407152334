import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoanInterface, NotifyFrequencyEnum } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-loan-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent  implements OnInit {
  @Input() loan!: LoanInterface;
  @Input() isGuardian: boolean = false;
  @Input() isKiddy: boolean = false;
  @Input() loanEditForm!: FormGroup

  @Output() editFamilyLoanFormSubmit = new EventEmitter<any>();

  staticBaseUrl: string =  environment.staticBaseUrl;

  isEditting: boolean = false;
  NotifyFrequencyEnumValues = Object.values(NotifyFrequencyEnum);

  familyLoanEditForm: FormGroup = new FormGroup({
    purpose: new FormControl(''),
    notify: new FormControl(''),
  });

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.familyLoanEditForm = this.formBuilder.group({
      purpose: [this.loan.purpose,
        [
          Validators.required,
        ]
      ],
      notify: [this.loan.notify,
        [
          Validators.required,
          Validators.pattern(this.NotifyFrequencyEnumValues.join('|'))
        ]
      ],
    })
  }

  startEditing() {
    this.isEditting = true;
  }

  stopEditing() {
    this.isEditting = false;
  }

  submitForm() {
    if (!this.familyLoanEditForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.familyLoanEditForm.value);
      this.editFamilyLoanFormSubmit.emit(requestData);
    }
  }

}
