import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BusinessCardComponent } from './card/card.component';
import { BusinessListComponent } from './list/list.component';

@NgModule({
  declarations: [BusinessCardComponent, BusinessListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatePipe, 
    IonicModule, 
  ],
  exports: [BusinessCardComponent, BusinessListComponent],
})
export class BusinessModule { }
