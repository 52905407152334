import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent implements OnInit {
  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

}
