<ion-item lines="none" class="task-main-item" routerDirection="root" [routerLink]="route">
  <ion-row class="tasks-row">
    <ion-col size="12" class="task-ion-body">
      <div>
        <ion-item lines="none" class="task-ion-item"
          ><ion-text class="task-title" color="primary">{{
            task.title
          }}</ion-text></ion-item
        >

        <ion-item lines="none" class="task-ion-item"
          ><ion-text class="task-body" color="amber"
            >{{ task.to_account.kiddy?.firstname }}
            {{ task.to_account.kiddy?.lastname }}</ion-text
          ></ion-item
        >
        <ion-item lines="none" class="task-ion-item">
          <ion-icon
            name="calendar-number"
            color="primary"
            class="sub-icon"
          ></ion-icon
          ><ion-text class="task-date" color="primary">{{
            task.creation_date | date : "mediumDate"
          }}</ion-text></ion-item
        >
      </div>
      <div class="reward-div">
        <ion-item lines="none" class="task-ion-item"
          ><ion-text class="reward-text">Reward</ion-text></ion-item
        >

        <ion-item lines="none" class="task-ion-item">
          <ion-text class="reward-amount">
            {{ task.from_account.country.currency.code }}
            {{ task.reward | number:'1.0-2' }}
          </ion-text>
        </ion-item>
        <ion-item lines="none" class="task-ion-item">
          <ion-button class="status-button" expand="block" [color]="getStatusColor(task.status)" size="small">{{ task.status }}</ion-button>
        </ion-item>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
