import { Component, Input, OnInit } from "@angular/core";
import { AllowanceInterface, AllowanceStatusEnum } from "@kiddy-cash/common";

@Component({
  standalone: false,
  selector: "kc-allowance-list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"],
})
export class ListItemComponent implements OnInit {
  @Input() allowance!: AllowanceInterface;
  @Input() route: (string | undefined)[] = [];

  constructor() {}

  ngOnInit() {}

  getStatusColor(status: AllowanceStatusEnum): string {
    if(status === AllowanceStatusEnum.Abandoned) return 'danger'
    if(status === AllowanceStatusEnum.Active) return 'success'
    if(status === AllowanceStatusEnum.Completed) return 'amber'
    if(status === AllowanceStatusEnum.Flagged) return 'warning'
    return 'medium';
  }

}
