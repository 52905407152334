import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { IonModal, ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { CountryInterface, ProfileInterface, ProfileRoleInterface, RolesEnum, UserInterface } from '@kiddy-cash/common';
import { CountryService, FamilyService, KiddyService, LoadingService, ToastService } from 'src/app/services';
import { ManagerInformationComponent } from '../manager-information/manager-information.component';
import { environment } from 'src/environments/environment';
import { ChildInformationComponent } from '../child-information/child-information.component';
import { parsePhoneNumber } from 'libphonenumber-js';

@Component({
  standalone: false,
  selector: 'kc-kiddy-business-manager-information',
  templateUrl: './kiddy-business-manager-information.component.html',
  styleUrls: ['./kiddy-business-manager-information.component.scss'],
})
export class KiddyBusinessManagerInformationComponent  implements OnInit {
  @ViewChild('modal', { static: true }) modal!: IonModal;
  @Input() user!: UserInterface 
  @Output() kiddyBusinessManagersChange = new EventEmitter<KiddyBusinessManagers>();

  kiddy_business_managers: KiddyBusinessManagers = {};

  hasRoles: boolean = true;
  hasKiddyRole: boolean = false;
  hasCustodianRole: boolean = false;

  custodian_profiles: ProfileInterface[] = [];
  kiddy_profiles: ProfileInterface[] = [];

  staticBaseUrl: string =  environment.staticBaseUrl;

  countries: CountryInterface[] = [];
  selectedCountry: CountryInterface | undefined;

  addManagerForm: FormGroup = new FormGroup({
    country_id: new FormControl(''),    
  });

  kiddyAccountAddForm: FormGroup = new FormGroup({
    country_id: new FormControl(''),    
  });

  isSubmitted: boolean  = false;
  isProcessing: boolean = false;

  name!: string;

  managers: Manager[] = [];
  numberOfManager: number = 0;
  previousNumberOfManager: number = 0;
  validManagers: boolean = false;

  children: Child[] = [];
  numberOfChildren: number = 0;
  previousNumberOfChildren: number = 0;
  validChildren: boolean = false;

  constructor(
    private familyService: FamilyService, 
    private kiddyService: KiddyService, 
    private countryService: CountryService,
    public formBuilder: FormBuilder,
    private modalCtrl: ModalController
  ) { 
    this.addManagerForm = this.formBuilder.group({
      country_id: ['', 
        [
          Validators.required, 
        ]
      ],
    })
    this.kiddyAccountAddForm = this.formBuilder.group({
      country_id: ['', 
        [
          Validators.required, 
        ]
      ],
    })
  }

  ngOnInit() {
    const user = this.user
    if(this.user){
      const profile = user.profile;
      const profile_roles = profile.profile_roles || [];

      profile_roles.length ? this.hasRoles = true : this.hasRoles = false

      const hasKiddyRole = (profile_role: ProfileRoleInterface) => profile_role.role.title === RolesEnum.Kiddy;
      const hasCustodianRole = (profile_role: ProfileRoleInterface) => profile_role.role.title === RolesEnum.Custodian;

      if(profile_roles.find(hasKiddyRole)) {
        this.hasKiddyRole = true
        this.kiddyService.getKiddyFamiliesManagers().subscribe(res => {
          const profiles: ProfileInterface[] = res.data.profiles;
          this.custodian_profiles = profiles;
        });
      } else {
        this.hasKiddyRole = false
      }

      if(profile_roles.find(hasCustodianRole)) {
        this.hasCustodianRole = true
        this.familyService.getFamiliesKiddies().subscribe(res => {
          const profiles: ProfileInterface[] = res.data.profiles;
          this.kiddy_profiles = profiles;
        });
      } else {
        this.hasCustodianRole = false
      }
    }

    this.countryService._$countries.subscribe((countries: CountryInterface[]) => {
      this.countries = countries;
    });

    this.countryService._$selectedCountry.subscribe((country: CountryInterface | undefined) => {
      if(!country) return;
      this.selectedCountry = country;
      this.addManagerForm.patchValue({
        country_id: country.id
      })
      this.kiddyAccountAddForm.patchValue({
        country_id: country.id
      })
    });
  }

  countrySelectionChanged(country: CountryInterface) {
    this.selectedCountry = country
    this.addManagerForm.patchValue({
      country_id: country.id
    })
  }

  handleManagerChange(e:any) {
    this.numberOfManager = parseInt(e.detail.value)
    this.createManagers(this.numberOfManager)
  }

  createManagers(count: number): void {
    if (this.previousNumberOfManager === 0) {
      for (let i = 0; i < count; i++) {
        const itemToAdd: Manager = {
            managerId: Math.random(),
            code: "" ,
            pin: "" ,
            alias : "", 
            firstname : "", 
            lastname : "" ,
            genderidentity: "",
            dob: "",
            valid: false
        }
        this.managers.push(itemToAdd);
      }
    } else {
      const numberOfNewKids = count - this.previousNumberOfManager
      if (numberOfNewKids > 0) {
        for (let  i = 0; i < numberOfNewKids; i++) {
          const itemToAdd: Manager = {
            managerId: Math.random(),
            code: "" ,
            pin: "" ,
            alias : "", 
            firstname : "", 
            lastname : "" ,
            genderidentity: "",
            dob: "",
            valid: false
        }
        this.managers.push(itemToAdd);
        }
      } else {
        this.managers = this.managers.slice(0, count)
      }
    }
    this.previousNumberOfManager = count
    this.validateManager()

    const kiddy_business_managers = this.kiddy_business_managers

    this.managers.length ? kiddy_business_managers.request_custodians = this.managers : delete kiddy_business_managers.request_custodians
    this.kiddy_business_managers = kiddy_business_managers;

    this.kiddyBusinessManagersChange.emit(this.kiddy_business_managers)
  }

  deleteManager(manager:any) {
    this.managers = this.managers.filter((newManager) => newManager.managerId !== manager.managerId)
  }

  handleChildrenChange(event:any) {
    this.numberOfChildren = parseInt(event.detail.value)
    this.createChildren(this.numberOfChildren)
  }

  createChildren(count: number): void {
    if (this.previousNumberOfChildren === 0) {
      for (let i = 0; i < count; i++) {
        const itemToAdd: Child = {
            childId: Math.random(),
            code: "" ,
            pin: "" ,
            firstname : "", 
            lastname : "" ,
            genderidentity: "",
            dob: "",
            valid: false
        }
        this.children.push(itemToAdd);
      }
    } else {
      const numberOfNewKids = count - this.previousNumberOfChildren
      if (numberOfNewKids > 0) {
        for (let  i = 0; i < numberOfNewKids; i++) {
          const itemToAdd: Child = {
            childId: Math.random(),
            code: "" ,
            pin: "" ,
            firstname : "", 
            lastname : "" ,
            genderidentity: "",
            dob: "",
            valid: false
          }
          this.children.push(itemToAdd);
        }
      } else {
        this.children = this.children.slice(0, count)
      }
    }

    this.previousNumberOfChildren = count
    this.validateChildren()

    const kiddy_business_managers = this.kiddy_business_managers

    this.children.length ? kiddy_business_managers.request_kiddies = this.children : delete kiddy_business_managers.request_kiddies
    this.kiddy_business_managers = kiddy_business_managers;

    this.kiddyBusinessManagersChange.emit(this.kiddy_business_managers)
  }

  deleteChild(child:any) {
    this.children = this.children.filter((newChild) => newChild.childId !== child.childId)
  }

  async openChildModal(child:Child) {
    const modal = await this.modalCtrl.create({
      component: ChildInformationComponent,
      componentProps: {
        child: child
      }
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role !== 'confirm') return;

    this.children = this.children.map((child:Child) => {
      if (child.childId === data.childId) {
        child = data
      }
      return child
    })

    this.validateChildren()

    this.children.map(child => {
      delete child.valid; 
      delete child.childId;

      if(child.phone){
        const parsedPhoneNumber = parsePhoneNumber(child.phone)
        const phoneData = parsedPhoneNumber?.number as string;
        child.phone = phoneData
      }

      return child;
    })

    const kiddy_business_managers = this.kiddy_business_managers

    this.children.length ? kiddy_business_managers.request_kiddies = this.children : delete kiddy_business_managers.request_kiddies
    this.kiddy_business_managers = kiddy_business_managers;

    this.kiddyBusinessManagersChange.emit(this.kiddy_business_managers)
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.modal.dismiss(this.name, 'confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
  }

  async openManagerModal(manager:Manager) {
    const modal = await this.modalCtrl.create({
      component: ManagerInformationComponent,
      componentProps: {
        manager: manager,
        canCreate: false,
      }
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role !== 'confirm') return;

    this.managers = this.managers.map((manager:Manager) => {
      if (manager.managerId === data.managerId) {
        manager = data
      }
      return manager
    })
    this.validateManager()

    this.managers.map(manager => {
      delete manager.valid; 
      delete manager.managerId;

      if(manager.phone){
        const parsedPhoneNumber = parsePhoneNumber(manager.phone)
        const phoneData = parsedPhoneNumber?.number as string;
        manager.phone = phoneData
      }

      return manager;
    })

    const kiddy_business_managers = this.kiddy_business_managers

    this.managers.length ? kiddy_business_managers.request_custodians = this.managers : delete kiddy_business_managers.request_custodians
    this.kiddy_business_managers = kiddy_business_managers;

    this.kiddyBusinessManagersChange.emit(this.kiddy_business_managers)
  }

  validateManager() {
    this.managers.map((manager) => {
      if (!manager.valid) {
        this.validManagers = false
        return
      } else {
        this.validManagers = true
      }
    })
  }

  validateChildren() {
    this.children.map((child) => {
      if (!child.valid) {
        this.validChildren = false
        return
      } else {
        this.validChildren = true
      }
    })
  }

  // set the data on the parent compoent
  handleCustodianChange(event: any){
    const custodian_ids: string[] = event.detail.value
    const kiddy_business_managers = this.kiddy_business_managers

    custodian_ids.length ? kiddy_business_managers.custodian_ids = custodian_ids : delete kiddy_business_managers.custodian_ids
    this.kiddy_business_managers = kiddy_business_managers;

    this.kiddyBusinessManagersChange.emit(this.kiddy_business_managers)
  }

  handleKiddyChange(event: any){
    const kiddy_ids: string[] = event.detail.value
    const kiddy_business_managers = this.kiddy_business_managers

    kiddy_ids.length ? kiddy_business_managers.kiddy_ids = kiddy_ids : delete kiddy_business_managers.kiddy_ids
    this.kiddy_business_managers = kiddy_business_managers;

    this.kiddyBusinessManagersChange.emit(this.kiddy_business_managers)
  }

}

interface Manager {
  managerId?: number;
  phone?: string;
  email?: string;
  code: string;
  pin: string;
  alias?: string;
  firstname: string;
  lastname: string;
  genderidentity: string;
  dob: string;
  valid?: boolean;
}

interface Child {
  childId?: number;
  phone?: string;
  email?: string;
  code: string;
  pin: string;
  firstname: string;
  lastname: string;
  genderidentity: string;
  dob: string;
  valid?: boolean;
}

interface KiddyBusinessManagers {
  custodian_ids?: string[];
  request_custodians?: Manager[];
  kiddy_ids?: string[];
  request_kiddies?: Child[];
}
