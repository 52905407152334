import { Component, Input, OnInit } from '@angular/core';
import { BusinessInterface, BusinessManagerRoleInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-business-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class BusinessCardComponent  implements OnInit {
  @Input() business!: BusinessInterface;

  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

  getBusinessManagerRoles(businessManagerRoles: BusinessManagerRoleInterface[]): string {
    return businessManagerRoles.map(businessManagerRole => businessManagerRole.role).join(', ');
  }
}
