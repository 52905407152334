<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()">Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-grid>
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size-xl="8" size-lg="9" size-sm="10" size="12">
        <object 
          [type]="mimetype" 
          [data]="data" 
          width="100%" 
          [height]="height"
        >
        </object>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>