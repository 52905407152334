import { Component, OnInit } from '@angular/core';
import { AppInfo } from '@capacitor/app';
import { DeviceInfo } from '@capacitor/device';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { CapacitorService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-mobile-app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
})
export class UpdateComponent  implements OnInit {

  staticBaseUrl: string =  environment.staticBaseUrl;
  appInfo!: AppInfo;
  deviceInfo!: DeviceInfo;

  constructor(
    private capacitorService: CapacitorService,
  ) {

  }

  ngOnInit() {
    this.capacitorService._$appInfo.subscribe(async (appInfo: AppInfo | undefined) => {
      if(appInfo) this.appInfo = appInfo;
    });

    this.capacitorService._$deviceInfo.subscribe(async (deviceInfo: DeviceInfo | undefined) => {
      if(deviceInfo) this.deviceInfo = deviceInfo;
    });
  }

  openAppStore = async () => {
    await AppUpdate.openAppStore();
  };
}
