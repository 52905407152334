<ion-app [ngStyle]="{ 'margin-top': deviceInfo?.platform === 'ios' ? (statusBarHeight + 'px') : '0' }">
  <ion-split-pane contentId="main-content" [disabled]="!isLoggedIn">
    <ion-menu contentId="main-content" type="overlay" [disabled]="!isLoggedIn" class="menu" >
      <ion-content color="background">

        <ion-grid class="ion-hide-lg-down">
          <ion-row class="ion-justify-content-center">
            <ion-col size="8">
              <ion-img
                routerDirection="root" routerLink="/dashboard" 
                src="{{ staticBaseUrl + '/kiddy-cash-logo.svg' }}"
                class="light" 
                height="40" 
                alt="logo" 
              ></ion-img>
              <ion-img
                routerDirection="root" routerLink="/dashboard" 
                src="{{ staticBaseUrl + '/kiddy-cash-white.svg' }}"
                class="dark" 
                height="40" 
                alt="logo" 
              ></ion-img>
            </ion-col>
          </ion-row>

          <ion-row class="ion-justify-content-center">
            <ng-container *ngIf="hasCustodianRole">

              <ion-col size="auto">
                <ion-list>
                  <ion-menu-toggle auto-hide="false">
                    <div class="list-background">
                      <ion-item routerDirection="root" routerLink="/dashboard" lines="none" detail="false" color="background">
                        <ion-icon name="home" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Home</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/family" lines="none" detail="false" color="background">
                        <ion-icon name="people" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Family</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/task" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="clipboard" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Tasks</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/allowance" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Allowances</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/loan" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="logo-usd" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Loans</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/goal" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="server" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Saving Goals</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/investment" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="cellular" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Investments</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/transaction" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="cash" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Transactions</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/payment-card" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="card" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Payment Cards</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/deposit" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Add Funds</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools" lines="none" detail="false" color="background">
                        <ion-icon name="school" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Schools</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools/request" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="add-circle" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Request</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools/account" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Accounts</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools/transaction" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="cash" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Transactions</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses" lines="none" detail="false" color="background">
                        <ion-icon name="business" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Kiddy Business</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses/create" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="add-circle" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Create</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses/account" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Accounts</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses/transaction" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="cash" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Transactions</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/business" lines="none" detail="false" color="background">
                        <ion-icon name="thumbs-up" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Follow Businesses</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/profile" lines="none" detail="false" color="background">
                        <ion-icon name="person-circle" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Profile</ion-label>
                      </ion-item>
            
                      <ion-item routerDirection="root" routerLink="/notifications" lines="none" detail="false" color="background">
                        <ion-icon name="notifications" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Notifications</ion-label>
                      </ion-item>

                      <ion-item (click)="openHelpCenter()" lines="none" detail="false" color="background">
                        <ion-icon name="help-circle" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Help Center</ion-label>
                      </ion-item>
  
                      <ion-item lines="none" detail="false" (click)="logout()" color="background">
                        <ion-icon name="log-out" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Logout</ion-label>
                      </ion-item>      
                    </div>
                  </ion-menu-toggle>
                </ion-list>
              </ion-col>

            </ng-container>
            <ng-container *ngIf="hasKiddyRole">

              <ion-col size="auto">
                <ion-list>
                  <ion-menu-toggle auto-hide="false">
                    <div>
  
                      <ion-item routerDirection="root" routerLink="/dashboard" lines="none" detail="false" color="background">
                        <ion-icon name="home" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">My Accounts</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/kiddy/task" lines="none" detail="false" color="background">
                        <ion-icon name="clipboard" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Tasks</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/kiddy/allowance" lines="none" detail="false" color="background">
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Allowances</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/kiddy/loan" lines="none" detail="false" color="background">
                        <ion-icon name="logo-usd" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Loans</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/kiddy/goal" lines="none" detail="false" color="background">
                        <ion-icon name="server" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Saving Goals</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/kiddy/investment" lines="none" detail="false" color="background">
                        <ion-icon name="cellular" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Investments</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/kiddy/transaction" lines="none" detail="false" color="background">
                        <ion-icon name="cash" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Transactions</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy/payment-card" lines="none" detail="false" color="background">
                        <ion-icon name="card" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Payment Cards</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools" lines="none" detail="false" color="background">
                        <ion-icon name="school" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Schools</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses" lines="none" detail="false" color="background">
                        <ion-icon name="business" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Kiddy Businesses</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/business" lines="none" detail="false" color="background">
                        <ion-icon name="thumbs-up" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Follow Businesses</ion-label>
                      </ion-item>

                      <ion-item (click)="openHelpCenter()" lines="none" detail="false" color="background">
                        <ion-icon name="help-circle" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Help Center</ion-label>
                      </ion-item>

                      <ion-item lines="none" detail="false" (click)="logout()" color="background">
                        <ion-icon name="log-out" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Logout</ion-label>
                      </ion-item>      
                    </div>
                  </ion-menu-toggle>
                </ion-list>
              </ion-col>

            </ng-container>
          </ion-row>
        </ion-grid>

        <ion-grid class="ion-hide-lg-up">
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-menu-toggle auto-hide="false">
                <ion-item color="background" routerDirection="root" routerLink="/profile">
                  <ion-avatar slot="start">
                    <img alt="avatar" src="{{ user?.profile?.avatarversion ? ( staticBaseUrl + '/profiles/' + user?.profile?.id + '/avatars/' + user?.profile?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg') }}"/>
                  </ion-avatar>
                  <ion-label color="primary">{{ user?.profile?.firstname }} {{ user?.profile?.lastname }}</ion-label>
                </ion-item>  
              </ion-menu-toggle>
            </ion-col>
          </ion-row>

          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-list>
                <ion-menu-toggle auto-hide="false">
                  <div>
                    <ng-container *ngIf="hasCustodianRole">
                      <ion-item routerDirection="root" routerLink="/dashboard" lines="none" detail="false" color="background">
                        <ion-icon name="home" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Home</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/family" lines="none" detail="false" color="background">
                        <ion-icon name="people" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Family</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/families/task" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="clipboard" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Tasks</ion-label>
                      </ion-item>
    
                      <ion-item routerDirection="root" routerLink="/families/allowance" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Allowances</ion-label>
                      </ion-item>
    
                      <ion-item routerDirection="root" routerLink="/families/loan" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="logo-usd" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Loans</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/families/goal" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="server" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Saving Goals</ion-label>
                      </ion-item>
    
                      <ion-item routerDirection="root" routerLink="/families/investment" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="cellular" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Investments</ion-label>
                      </ion-item>  
    
                      <ion-item routerDirection="root" routerLink="/families/transaction" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="cash" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Transactions</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/payment-card" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="card" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Payment Cards</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/families/deposit" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Add Funds</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools" lines="none" detail="false" color="background">
                        <ion-icon name="school" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Schools</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools/request" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="add-circle" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Request</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools/account" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Accounts</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools/transaction" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="cash" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Transactions</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses" lines="none" detail="false" color="background">
                        <ion-icon name="business" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Kiddy Business</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses/create" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="add-circle" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Create</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses/account" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Accounts</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses/transaction" lines="none" detail="false" color="background">
                        <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                        <ion-icon name="cash" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Transactions</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/business" lines="none" detail="false" color="background">
                        <ion-icon name="thumbs-up" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Follow Businesses</ion-label>
                      </ion-item>

                    </ng-container>
  
                    <ng-container *ngIf="hasKiddyRole">
                      <ion-item routerDirection="root" routerLink="/dashboard" lines="none" detail="false" color="background">
                        <ion-icon name="home" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">My Accounts</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/kiddy/task" lines="none" detail="false" color="background">
                        <ion-icon name="clipboard" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Tasks</ion-label>
                      </ion-item>
    
                      <ion-item routerDirection="root" routerLink="/kiddy/allowance" lines="none" detail="false" color="background">
                        <ion-icon name="wallet" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Allowances</ion-label>
                      </ion-item>
    
                      <ion-item routerDirection="root" routerLink="/kiddy/loan" lines="none" detail="false" color="background">
                        <ion-icon name="logo-usd" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Loans</ion-label>
                      </ion-item>
  
                      <ion-item routerDirection="root" routerLink="/kiddy/goal" lines="none" detail="false" color="background">
                        <ion-icon name="server" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Saving Goals</ion-label>
                      </ion-item>
    
                      <ion-item routerDirection="root" routerLink="/kiddy/investment" lines="none" detail="false" color="background">
                        <ion-icon name="cellular" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Investments</ion-label>
                      </ion-item>  
    
                      <ion-item routerDirection="root" routerLink="/kiddy/transaction" lines="none" detail="false" color="background">
                        <ion-icon name="cash" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Transactions</ion-label>
                      </ion-item>  

                      <ion-item routerDirection="root" routerLink="/kiddy/payment-card" lines="none" detail="false" color="background">
                        <ion-icon name="card" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Payment Cards</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/schools" lines="none" detail="false" color="background">
                        <ion-icon name="school" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Schools</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/kiddy-businesses" lines="none" detail="false" color="background">
                        <ion-icon name="business" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Kiddy Business</ion-label>
                      </ion-item>

                      <ion-item routerDirection="root" routerLink="/business" lines="none" detail="false" color="background">
                        <ion-icon name="thumbs-up" class="mr-20" color="primary"></ion-icon>
                        <ion-label color="primary">Follow Businesses</ion-label>
                      </ion-item>
                    </ng-container>
  
                    <ion-item routerDirection="root" routerLink="/profile" lines="none" detail="false" color="background">
                      <ion-icon name="person-circle" class="mr-20" color="primary"></ion-icon>
                      <ion-label color="primary">Profile</ion-label>
                    </ion-item>
          
                    <ion-item routerDirection="root" routerLink="/notifications" lines="none" detail="false" color="background">
                      <ion-icon name="notifications" class="mr-20" color="primary"></ion-icon>
                      <ion-label color="primary">Notifications</ion-label>
                    </ion-item>

                    <ion-item (click)="openHelpCenter()" lines="none" detail="false" color="background">
                      <ion-icon name="help-circle" class="mr-20" color="primary"></ion-icon>
                      <ion-label color="primary">Help Center</ion-label>
                    </ion-item>
                  </div>
                </ion-menu-toggle>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-content>

      <ion-footer class="ion-hide-lg-down list-background">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-menu-toggle auto-hide="false">
                <ion-item routerDirection="root" routerLink="/profile" lines="none" detail="false" color="background">
                  <ion-avatar slot="start">
                    <img alt="avatar" src="{{ user?.profile?.avatarversion ? ( staticBaseUrl + '/profiles/' + user?.profile?.id + '/avatars/' + user?.profile?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg') }}"/>
                  </ion-avatar>
                  <ion-label color="primary">{{ user?.profile?.firstname }} {{ user?.profile?.lastname }}</ion-label>
                </ion-item>  
              </ion-menu-toggle>
            </ion-col>
          </ion-row>  
        </ion-grid>
      </ion-footer>

      <ion-footer class="ion-hide-lg-up list-background">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="10">
              <ion-list>
                <ion-menu-toggle auto-hide="false">
                  <div class="list-background">
                    <ion-item lines="none" detail="false" (click)="logout()" color="background">
                      <ion-icon name="log-out" class="mr-20" color="primary"></ion-icon>
                      <ion-label color="primary">Logout</ion-label>
                    </ion-item>
                  </div>
                </ion-menu-toggle>  
              </ion-list>
            </ion-col>
          </ion-row>  
        </ion-grid>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content" routerDirection="root"></ion-router-outlet>
  </ion-split-pane>
</ion-app>