import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DocumentViewerComponent } from './viewer/viewer.component'
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    DocumentViewerComponent,
  ],
  imports: [
    CommonModule,
    DatePipe, 
    IonicModule, 
    RouterModule,
  ],
  exports: [
    DocumentViewerComponent,
  ],
})
export class DocumentModule { }
