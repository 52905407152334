import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GoalInterface, GoalStatusEnum, NotifyFrequencyEnum } from '@kiddy-cash/common';
import Cropper from 'cropperjs';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-goal-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent  implements OnInit {
  @ViewChild('fileInput', { static: false }) input!: ElementRef<HTMLInputElement>;
  @ViewChild('cropperContainer', { static: false }) div!: ElementRef<HTMLDivElement>;
  cropper: Cropper | undefined;

  @Input() goal!: GoalInterface;
  @Input() isGuardian: boolean = false;
  @Input() isKiddy: boolean = false;
  @Input() goalEditForm!: FormGroup

  @Output() editKiddyGoalFormSubmit = new EventEmitter<any>();

  staticBaseUrl: string =  environment.staticBaseUrl;

  isEditting: boolean = false;
  GoalStatusEnumValues = Object.values(GoalStatusEnum);
  NotifyFrequencyEnumValues = Object.values(NotifyFrequencyEnum);

  kiddyGoalForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    target: new FormControl(''),
    notify: new FormControl(''),
    status: new FormControl(''),
  });

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.kiddyGoalForm = this.formBuilder.group({
      name: [this.goal.name,
        [
          Validators.required,
        ]
      ],
      target: [this.goal.target,
        [
          Validators.required,
          Validators.min(this.goal.reached || 0)
        ]
      ],
      status: [this.goal.status,
        [
          Validators.required,
          Validators.pattern(this.GoalStatusEnumValues.join('|'))
        ]
      ],
      notify: [this.goal.notify,
        [
          Validators.required,
          Validators.pattern(this.NotifyFrequencyEnumValues.join('|'))
        ]
      ],
    })
  }

  startEditing() {
    this.isEditting = true;

    setTimeout(() => {
      this.setupCropper();
    }, 250)
  }

  stopEditing() {
    this.isEditting = false;
  }

  setupCropper() {
    const component = this;
    const inputElement = this.input?.nativeElement;
    const cropperContainerElement = this.div?.nativeElement;

    inputElement.addEventListener('change', ()=>{
      if(inputElement.files === null || inputElement.files.length === 0) {
        this.destroyCropper(cropperContainerElement);
        return;
      }

      const imageFile = inputElement.files[0]

      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = function (event: ProgressEvent<FileReader>) {
        if(event.target === null || !reader.result) return;

        const image: HTMLImageElement = new Image();
        image.src = reader.result.toString();
        image.onload = function () {
          if(component.cropper) {
            // if existing cropper, then let's replace the image
            component.replaceCropperImage(image)
          } else {
            // if no cropper exists, then let's initialize one
            component.initializeCropper(image, cropperContainerElement);
          }
        };
      }
    });
  }

  initializeCropper(image: HTMLImageElement, cropperContainerElement: HTMLDivElement){
    this.cropper = new Cropper(image, {
      container: cropperContainerElement,
    });
    this.cropper.getCropperSelection()!.initialAspectRatio = 1;
    this.cropper.getCropperSelection()!.aspectRatio = 1;
    this.cropper.getCropperCanvas()!.style.minHeight = "400px";
    this.cropper.getCropperSelection()!.$render();
    this.cropper.getCropperSelection()!.$center();
  }

  replaceCropperImage(image: HTMLImageElement){
    this.cropper!.getCropperImage()!.$image.src = image.src;
  }

  destroyCropper(cropperContainerElement : HTMLDivElement){
    this.cropper = undefined;
    cropperContainerElement.replaceChildren();
  }

  async submitForm() {
    if (!this.kiddyGoalForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.kiddyGoalForm.value);

      if(this.cropper){
        const canvas = await this.cropper.getCropperSelection()!.$toCanvas({
          width: 400,
          height: 400,
        });
        const image = canvas.toDataURL("image/jpeg", 0.8);
        requestData.image = image;
      }

      this.editKiddyGoalFormSubmit.emit(requestData);
    }
  }

}
