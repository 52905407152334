import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KiddyService {
  constructor(private http: HttpClient) { }

  // GET Requests
  getKiddyFamilies() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/families`);
  }

  getKiddyFamiliesManagers() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/families/managers`);
  }

  getKiddyAccounts() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account`);
  }

  getKiddyAccount(kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}`);
  }

  getKiddyAllowances() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/allowance`);
  }

  getKiddyAccountAllowances(kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/allowance`);
  }

  getKiddyAccountAllowance(kiddy_account_id: string, allowance_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/allowance/${allowance_id}`);
  }

  getKiddyGoals() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/goal`);
  }

  getKiddyAccountGoals(kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/goal`);
  }

  getKiddyAccountGoal(kiddy_account_id: string, goal_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/goal/${goal_id}`);
  }

  getKiddyInvestments() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/investment`);
  }

  getKiddyAccountInvestments(kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/investment`);
  }

  getKiddyAccountInvestment(kiddy_account_id: string, investment_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/investment/${investment_id}`);
  }

  getKiddyLoans() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/loan`);
  }

  getKiddyAccountLoans(kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/loan`);
  }

  getKiddyAccountLoan(kiddy_account_id: string, loan_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/loan/${loan_id}`);
  }

  getKiddyTasks() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/task`);
  }

  getKiddyAccountTasks(kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/task`);
  }

  getKiddyAccountTask(kiddy_account_id: string, task_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/task/${task_id}`);
  }

  getKiddyTransactions() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/transaction`);
  }

  getKiddyAccountTransactions(kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/transaction`);
  }

  getKiddyAccountTransaction(kiddy_account_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/transaction/${transaction_id}`);
  }

  getKiddyPaymentCards() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/payment_card`);
  }

  getKiddyAccountPaymentCards(kiddy_account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/payment_card`);
  }

  getKiddyAccountPaymentCard(kiddy_account_id: string, payment_card_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/payment_card/${payment_card_id}`);
  }

  //POST Requests
  goalForKiddyAccount(kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/goal`, data);
  }

  depositSavingGoalForKiddyAccount(kiddy_account_id: string, goal_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/goal/${goal_id}/deposit`, data);
  }

  withdrawSavingGoalForKiddyAccount(kiddy_account_id: string, goal_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/goal/${goal_id}/withdraw`, data);
  }

  investmentForKiddyAccount(kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/investment`, data);
  }

  depositInvestmentForKiddyAccount(kiddy_account_id: string, goal_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/investment/${goal_id}/deposit`, data);
  }

  withdrawInvestmentForKiddyAccount(kiddy_account_id: string, goal_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/investment/${goal_id}/withdraw`, data);
  }

  payLoanForKiddyAccount(kiddy_account_id: string, loan_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/loan/${loan_id}/pay`, data);
  }

  transactionForKiddyAccount(kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/transaction`, data);
  }

  reviewTransactionForKiddyAccount(kiddy_account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/transaction/review`, data);
  }

  //Update Requests
  updateKiddyAccount(kiddy_account_id: string,  data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}`, data);
  }

  updateAllowanceForKiddyAccount(kiddy_account_id: string, allowance_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/allowance/${allowance_id}`, data);
  }

  updateGoalForKiddyAccount(kiddy_account_id: string, goal_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/goal/${goal_id}`, data);
  }

  updateInvestmentForKiddyAccount(kiddy_account_id: string, investment_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/investment/${investment_id}`, data);
  }

  updateLoanForKiddyAccount(kiddy_account_id: string, loan_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/loan/${loan_id}`, data);
  }

  updateTaskForKiddyAccount(kiddy_account_id: string, task_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/task/${task_id}`, data);
  }

  updateKiddyAccountTaskStatus(kiddy_account_id: string, task_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/task/${task_id}/status`, data);
  }
  
  updateTransactionForKiddyAccount(kiddy_account_id: string, transaction_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/transaction/${transaction_id}`, data);
  }

  updatePaymentCardForKiddyAccount(kiddy_account_id: string, payment_card_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy/account/${kiddy_account_id}/payment_card/${payment_card_id}`, data);
  }

}
