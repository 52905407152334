import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  constructor(private http: HttpClient) { }

  // Data Requests i.e publicly cacheable
  searchSchools(country_id: number, school_name: string) {
    return this.http.get<any>(`${environment.dataBaseUrl}/school/country/${country_id}/${school_name}`);
  }

  getSchoolInfo(school_id: string) {
    return this.http.get<any>(`${environment.dataBaseUrl}/school/${school_id}`);
  }

  // GET Requests
  getAllSchools() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school`);
  }

  getSchoolClasses() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/class`);
  }

  getKiddySchoolsIds() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/ids`);
  }

  getKiddySchoolsAccounts() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/account`);
  }

  getKiddySchoolsTransactions() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/transaction`);
  }

  getSchoolClassRequests() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/class-requests`);
  }

  getKiddySchool(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}`);
  }

  getKiddySchoolAccounts(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}/account`);
  }

  getKiddySchoolTransactions(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}/transaction`);
  }

  getKiddySchoolAccount(school_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}/account/${account_id}`);
  }

  getKiddySchoolAccountTransactions(school_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}/account/${account_id}/transaction`);
  }

  getKiddySchoolAccountTransaction(school_id: string, account_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}/account/${account_id}/transaction/${transaction_id}`);
  }


  // Update Requests
  updateSchoolAccount(school_id: string, account_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}/account/${account_id}`, data);
  }


  //POST Requests
  createKiddySchoolClassRequest(school_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}`, data);
  }

  transactionForKiddySchoolAccount(school_id: string, account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}/account/${account_id}/transaction`, data);
  }

  postSchoolClassRequests(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-school/class-requests`, data);
  }

  // Delete Requests
  closeKiddySchoolAccount(school_id: string, account_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/kiddy-school/${school_id}/account/${account_id}`);
  }

}
